import React, { useState } from 'react';
import { db } from '../firebase';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import '../App.css';

const prefectures = [
  '北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県',
  '福島県', '茨城県', '栃木県', '群馬県', '埼玉県', '千葉県',
  '東京都', '神奈川県', '新潟県', '富山県', '石川県', '福井県',
  '山梨県', '長野県', '岐阜県', '静岡県', '愛知県', '三重県',
  '滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県',
  '鳥取県', '島根県', '岡山県', '広島県', '山口県', '徳島県',
  '香川県', '愛媛県', '高知県', '福岡県', '佐賀県', '長崎県',
  '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県'
];

const InboundShopRegister = () => {
  const [shopCode, setShopCode] = useState('');
  const [shopName, setShopName] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [prefecture, setPrefecture] = useState('');
  const [city, setCity] = useState('');
  const [street, setStreet] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  // Save shop to Firestore
  const handleRegisterShop = async (e) => {
    e.preventDefault();
    if (!shopCode || !shopName || !prefecture) {
      alert('すべての項目を入力してください。');
      return;
    }

    setLoading(true);
    try {
      // Check if the shop code already exists
      const q = query(collection(db, 'inbound_shops'), where('code', '==', shopCode));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        alert('この取引先コードは既に存在します。別のコードを入力してください。');
      } else {
        await addDoc(collection(db, 'inbound_shops'), {
          code: shopCode,
          name: shopName,
          address: {
            postalCode,
            prefecture,
            city,
            street,
          },
          phone,
          email,
        });
        setSuccessMessage('店舗が登録されました！');
        setShopCode('');
        setShopName('');
        setPostalCode('');
        setPrefecture('');
        setCity('');
        setStreet('');
        setPhone('');
        setEmail('');
        navigate('/seimen/inbound-shop-manage');
      }
    } catch (error) {
      console.error('Error registering shop: ', error);
      alert('店舗登録中にエラーが発生しました。');
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate('/seimen/inbound-shop-manage');
  };

  return (
    <div className="InboundRegisterApp">
      <h1>入庫先管理 - 新規登録</h1>
      <form className="shop-register-form" onSubmit={handleRegisterShop}>
        <div className="InboundRegisterFormGroup">
          <label htmlFor="shopCode">
            コード <span className="required">(必須)</span>
          </label>
          <input
            type="text"
            id="shopCode"
            value={shopCode}
            onChange={(e) => setShopCode(e.target.value)}
            placeholder="2201"
          />
        </div>

        <div className="InboundRegisterFormGroup">
          <label htmlFor="shopName">
            取引先名 <span className="required">(必須)</span>
          </label>
          <input
            type="text"
            id="shopName"
            value={shopName}
            onChange={(e) => setShopName(e.target.value)}
            placeholder="新潟食肉"
          />
        </div>

        <div className="InboundRegisterFormGroup">
          <label htmlFor="postalCode">
            郵便番号 
          </label>
          <input
            type="text"
            id="postalCode"
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
            placeholder="123-4567"
          />
        </div>

        <div className="InboundRegisterFormGroup">
          <label htmlFor="prefecture">
            都道府県 <span className="required">(重要)</span>
          </label>
          <select
            id="prefecture"
            value={prefecture}
            onChange={(e) => setPrefecture(e.target.value)}
          >
            <option value="" disabled>
              都道府県
            </option>
            {prefectures.map((pref, index) => (
              <option key={index} value={pref}>
                {pref}
              </option>
            ))}
          </select>
        </div>

        <div className="InboundRegisterFormGroup">
          <label htmlFor="city">
            市区町村
          </label>
          <input
            type="text"
            id="city"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            placeholder="中央区花園"
          />
        </div>

        <div className="InboundRegisterFormGroup">
          <label htmlFor="street">
            番地・建物名
          </label>
          <input
            type="text"
            id="street"
            value={street}
            onChange={(e) => setStreet(e.target.value)}
            placeholder="1-3-61・新潟ビル1F"
          />
        </div>

        <div className="InboundRegisterFormGroup">
          <label htmlFor="phone">
            電話番号 
          </label>
          <input
            type="text"
            id="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="123-4567-8901"
          />
        </div>

        <div className="InboundRegisterFormGroup">
          <label htmlFor="email">
            メールアドレス 
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="nigata@darumaya.com"
          />
        </div>

        <div className="InboundRegisterButtons">
          <button type="submit" className="InboundRegisterSubmitButton" disabled={loading}>
            {loading ? '登録中...' : '店舗を登録'}
          </button>
          <button className="InboundRegisterCancelButton" onClick={handleBack}>
            キャンセル
          </button>
        </div>
      </form>

      {successMessage && <p className="success-message">{successMessage}</p>}
    </div>
  );
};

export default InboundShopRegister;
