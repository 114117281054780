// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBseXvQwE_m9-gSmMnKW-YVVaRt8CK6xUQ",
    authDomain: "kameda-seimen.firebaseapp.com",
    databaseURL: "https://kameda-seimen-default-rtdb.firebaseio.com",
    projectId: "kameda-seimen",
    storageBucket: "kameda-seimen.appspot.com",
    messagingSenderId: "872174550502",
    appId: "1:872174550502:web:54ef1190819b391696aa27",
    measurementId: "G-90NF73E4E3"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };
