import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import '../Pages.css'; 

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/seimen/');
    } catch (error) {
      let message = 'ログインに失敗しました。';

      switch (error.code) {
        case 'auth/invalid-email':
          message = '無効なメールアドレスです。';
          break;
        case 'auth/user-disabled':
          message = 'このアカウントは無効化されています。';
          break;
        case 'auth/user-not-found':
          message = '登録されていないメールアドレスです。';
          break;
        case 'auth/wrong-password':
          message = 'パスワードが間違っています。';
          break;
        default:
          message += '';
      }

      alert(message);
    }
  };

  return (
    <div className="login-wrapper">
      <div className="login-card">
        <h2 className="login-title">ログイン</h2>
        <form onSubmit={handleLogin} className="login-form">
          <input
            type="email"
            placeholder="メールアドレス"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="login-input"
          />

          {/* パスワード with toggle */}
          <div className="password-wrapper">
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder="パスワード"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="login-input"
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="toggle-password"
            >
              {showPassword ? '非表示' : '表示'}
            </button>
          </div>

          <button type="submit" className="login-button">ログイン</button>
        </form>
        <p className="login-subtext">
          アカウントをお持ちでない方は{' '}
          <button
            className="signup-link"
            onClick={() => navigate('/seimen/signup')}
          >
            会員登録
          </button>
        </p>
      </div>
    </div>
  );
}

export default Login;
