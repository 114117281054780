import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, addDoc, updateDoc, doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import '../App.css';

const OutboundRegister = () => {
  const [date, setDate] = useState('');
  const [outboundItems, setOutboundItems] = useState([{ recipeName: '', unitPrice: 0, outputQuantity: 0, totalCost: 0 }]);
  const [recipes, setRecipes] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRecipes = async () => {
      const querySnapshot = await getDocs(collection(db, 'recipes'));
      const recipesList = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setRecipes(recipesList);
    };

    fetchRecipes();
  }, []);

  const addOutboundItem = () => {
    setOutboundItems([...outboundItems, { recipeName: '', unitPrice: 0, outputQuantity: 0, totalCost: 0 }]);
  };

  const handleOutboundItemChange = (index, field, value) => {
    const newOutboundItems = [...outboundItems];
  
    if (field === 'recipeName') {
      newOutboundItems[index].recipeName = value;
  
      // Find the selected recipe and assign unit and unitPrice
      const selectedRecipe = recipes.find((recipe) => recipe.name === value);
  
      if (selectedRecipe) {
        const totalCost = parseFloat(selectedRecipe.totalCost || 0);
        const productionQuantity = parseFloat(selectedRecipe.productionQuantity || 1);
  
        // Assign unit and calculate unit price
        newOutboundItems[index].unit = selectedRecipe.unit || ''; // Assign the unit
        const unitPrice = totalCost / productionQuantity;
        newOutboundItems[index].unitPrice = parseFloat(unitPrice.toFixed(2));
      } else {
        newOutboundItems[index].unitPrice = 0;
        newOutboundItems[index].unit = ''; // Reset unit if no recipe is selected
      }
  
      // Recalculate total cost
      newOutboundItems[index].totalCost = newOutboundItems[index].unitPrice * (newOutboundItems[index].outputQuantity || 0);
    } else if (field === 'outputQuantity') {
      const quantity = parseFloat(value) || 0;
      newOutboundItems[index].outputQuantity = quantity;
  
      const selectedRecipe = recipes.find((recipe) => recipe.name === newOutboundItems[index].recipeName);
  
      if (selectedRecipe) {
        const unitPrice = parseFloat(selectedRecipe.totalCost || 0) / parseFloat(selectedRecipe.productionQuantity || 1);
        newOutboundItems[index].totalCost = parseFloat((unitPrice * quantity).toFixed(2));
      } else {
        newOutboundItems[index].totalCost = 0;
      }
    }
  
    setOutboundItems(newOutboundItems);
  };
  
  
  
  
  

  const saveOutboundData = async () => {
    if (!date) {
      alert('日付を入力してください。');
      return;
    }
  
    const validItems = outboundItems.filter((item) => item.recipeName !== '' && item.outputQuantity > 0);
  
    if (validItems.length === 0) {
      alert('仕入れ商品名を選択し、出産数を入力してください。');
      return;
    }
  
    setLoading(true);
    try {
      // Pre-validate all stock requirements
      for (const item of validItems) {
        const recipe = recipes.find((r) => r.name === item.recipeName);
  
        if (recipe) {
          for (const ingredient of recipe.ingredients) {
            const stockDoc = await getDocs(collection(db, 'stock'));
            const currentStock = stockDoc.docs.find((doc) => doc.id === ingredient.name);
  
            if (currentStock) {
              const currentData = currentStock.data();
              const currentQuantityInGrams = convertToGrams(currentData.quantity, currentData.unit);
              const ingredientRequiredInGrams =
                (ingredient.quantity / recipe.productionQuantity) * item.outputQuantity;
  
              if (currentQuantityInGrams < ingredientRequiredInGrams) {
                alert(`在庫不足: ${ingredient.name}`);
                throw new Error(`在庫不足: ${ingredient.name}`);
              }
            } else {
              alert(`在庫が見つかりません: ${ingredient.name}`);
              throw new Error(`在庫が見つかりません: ${ingredient.name}`);
            }
          }
        }
      }
  
      // If validation passes, proceed with saving data
      const outboundData = {
        date,
        totalCost: validItems.reduce((acc, item) => acc + item.totalCost, 0),
        items: await Promise.all(
          validItems.map(async (item) => {
            const recipe = recipes.find((r) => r.name === item.recipeName);
            const productUsage = [];
  
            if (recipe) {
              for (const ingredient of recipe.ingredients) {
                const stockRef = doc(db, 'stock', ingredient.name);
                const stockDoc = await getDocs(collection(db, 'stock'));
                const currentStock = stockDoc.docs.find((doc) => doc.id === ingredient.name);
  
                if (currentStock) {
                  const currentData = currentStock.data();
                  const currentQuantityInGrams = convertToGrams(currentData.quantity, currentData.unit);
                  const ingredientRequiredInGrams =
                    (ingredient.quantity / recipe.productionQuantity) * item.outputQuantity;
  
                  // Update stock
                  const updatedQuantityInGrams = currentQuantityInGrams - ingredientRequiredInGrams;
                  const updatedQuantityInKg = updatedQuantityInGrams / 1000;
  
                  await updateDoc(stockRef, {
                    quantity: updatedQuantityInKg,
                    unit: 'kg',
                  });
  
                  // Calculate product usage
                  const ingredientUsage = ingredientRequiredInGrams / 1000; // Convert to kilograms
                  const pricePerGram = ingredient.cost / ingredient.quantity || 0;
                  const productPrice = parseFloat((pricePerGram * ingredientUsage * 1000).toFixed(2));
  
                  productUsage.push({
                    name: ingredient.name,
                    usage: parseFloat(ingredientUsage.toFixed(2)),
                    price: productPrice,
                  });
                }
              }
            }
  
            // Update production collection
            const productionRef = collection(db, 'production');
            const productionSnapshot = await getDocs(productionRef);
            const existingProduction = productionSnapshot.docs.find(
              (doc) => doc.data().recipeName === item.recipeName
            );
  
            if (existingProduction) {
              // Update existing production quantity
              const productionDocRef = doc(db, 'production', existingProduction.id);
              await updateDoc(productionDocRef, {
                unit: recipe.unit || '',
                productionQuantity:
                  (existingProduction.data().productionQuantity || 0) + item.outputQuantity,
              });
            } else {
              // Add new production record
              await addDoc(productionRef, {
                recipeName: item.recipeName,
                unit: recipe.unit || '',
                productionQuantity: item.outputQuantity,
              });
            }
  
            return {
              recipeName: item.recipeName,
              outputQuantity: item.outputQuantity,
              unitPrice: item.unitPrice, // Save unitPrice in Firebase
              totalCost: item.totalCost.toFixed(2),
              products: productUsage,
            };
          })
        ),
      };
  
      // Save outbound data to the "out_stock" collection
      await addDoc(collection(db, 'out_stock'), outboundData);
  
      setDate('');
      setOutboundItems([{ recipeName: '', unitPrice: 0, outputQuantity: 0, totalCost: 0 }]);
      alert('仕入れが登録され、在庫が更新されました！');
      navigate('/seimen/outbound');
    } catch (error) {
      console.error('Error saving outbound data:', error);
      alert(`エラー: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };
  
  
  

  const convertToGrams = (quantity, unit) => {
    switch (unit) {
      case 'kg':
        return quantity * 1000;
      case 'ml':
        return quantity;
      case 'g':
      default:
        return quantity;
    }
  };

  const handleBack = () => {
    navigate('/seimen/outbound');
  };

  const removeOutboundItem = (index) => {
    const updatedItems = outboundItems.filter((_, i) => i !== index);
    setOutboundItems(updatedItems);
  };

  const getAvailableRecipes = (currentIndex) => {
    const selectedRecipes = outboundItems.map((item) => item.recipeName);
    return recipes.filter(
      (recipe) => !selectedRecipes.includes(recipe.name) || recipe.name === outboundItems[currentIndex].recipeName
    );
  };
  
  
  return (
    <div className="App InboundRegisterApp">
      <h1>仕入れ登録</h1>
      <div className="RecipeFormGroup">
        <label>日付け<span>(必須)</span></label>
        <div className="date_container">
          <input name="date" type="date" value={date} onChange={(e) => setDate(e.target.value)} lang="ja" className="date_picker" id='date'/>
        </div>
      </div>

      <div className="table-container">
        <table className="RecipeRegisterTable">
          <thead>
            <tr>
              <th>仕入れ商品名</th>
              <th>出産数</th>
              <th>単価価格</th>
              <th>合計金額</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            {outboundItems.map((item, index) => (
              <tr key={index}>
                <td>
                  <select
                    value={item.recipeName}
                    onChange={(e) => handleOutboundItemChange(index, 'recipeName', e.target.value)}
                  >
                    <option value="">商品を選択</option>
                    {getAvailableRecipes(index).map((recipe) => (
                      <option key={recipe.id} value={recipe.name}>
                        {recipe.name}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <input
                    type="number"
                    value={isNaN(item.outputQuantity) || item.outputQuantity === undefined ? '' : item.outputQuantity}
                    onChange={(e) => handleOutboundItemChange(index, 'outputQuantity', e.target.value)}
                    disabled={!item.recipeName}
                  />
                  <span> {item.unit || ''}</span>
                </td>
                  <td>
                  <input
                    type="text"
                    value={item.unitPrice ? item.unitPrice.toFixed(2) : '0.00'}
                    disabled
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={isNaN(item.totalCost) || item.totalCost === undefined ? '' : item.totalCost.toFixed(2)}
                    disabled
                  />
                </td>
                <td>
                  <button className="delete-button" onClick={() => removeOutboundItem(index)}>
                    削除
                  </button>
                </td>
              </tr>
            ))}
          </tbody>

        </table>
      </div>

      <button className="RecipeRegisterAddButton" onClick={addOutboundItem}>
        仕入れ商品を追加
      </button>

      <div className="InboundRegisterButtons">
        <button className="InboundRegisterSubmitButton" onClick={saveOutboundData} disabled={loading}>
          {loading ? '登録中...' : '仕入れを保存する'}
        </button>
        <button className="InboundRegisterCancelButton" onClick={handleBack}>
          キャンセル
        </button>
      </div>
    </div>
  );
};

export default OutboundRegister;