import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';

const Shipments = () => {
  const [shipments, setShipments] = useState([]);
  const [filteredShipments, setFilteredShipments] = useState([]);
  const [filters, setFilters] = useState({ year: '', month: '', from: '', to: '' });
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // 1ページあたりの表示数
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const shipmentSnapshot = await getDocs(collection(db, 'shipments'));
        const shipmentData = shipmentSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        const sortedShipments = shipmentData.sort((a, b) => new Date(b.date) - new Date(a.date));
        setShipments(sortedShipments);
        setFilteredShipments(sortedShipments);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleDelete = async (shipmentId) => {
    const confirmDelete = window.confirm('この出荷データを削除してもよろしいですか？');
    if (!confirmDelete) return;

    try {
      await deleteDoc(doc(db, 'shipments', shipmentId));
      setShipments((prevShipments) =>
        prevShipments.filter((shipment) => shipment.id !== shipmentId)
      );
      setFilteredShipments((prevShipments) =>
        prevShipments.filter((shipment) => shipment.id !== shipmentId)
      );
      alert('出荷データが削除されました。');
    } catch (error) {
      console.error('Error deleting shipment:', error);
      alert('削除に失敗しました。');
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const applyFilters = () => {
    const { year, month, from, to } = filters;

    let filtered = [...shipments];

    if (year) {
      filtered = filtered.filter((shipment) => shipment.date?.startsWith(year));
    }

    if (month) {
      filtered = filtered.filter((shipment) => shipment.date?.startsWith(`${year}-${month.padStart(2, '0')}`));
    }

    if (from && to) {
      filtered = filtered.filter((shipment) => shipment.date >= from && shipment.date <= to);
    }

    setFilteredShipments(filtered);
    setShowDownloadButton(true);
  };

  const downloadExcel = () => {
    if (filteredShipments.length === 0) {
      alert('データがありません');
      return;
    }

    const headers = ['日付', '店舗', '商品名', '数量', '単価', '合計金額'];
    const excelData = [
      headers,
      ...filteredShipments.map((shipment) => [
        shipment.date || '不明',
        shipment.shopName || '不明',
        shipment.productName || '不明',
        shipment.quantity || 0,
        shipment.unitPrice?.toFixed(2) || '0.00',
        shipment.totalCost?.toFixed(2) || '0.00',
      ]),
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Shipments');

    const today = new Date();
    const formattedDate = `${today.getFullYear()}${String(today.getMonth() + 1).padStart(2, '0')}${String(today.getDate()).padStart(2, '0')}`;
    const fileName = `Shipments_${formattedDate}.xlsx`;

    XLSX.writeFile(workbook, fileName);
  };

  const paginate = (data) => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return data.slice(indexOfFirstItem, indexOfLastItem);
  };

  const totalPages = Math.ceil(filteredShipments.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  if (loading) {
    return <p>データを読み込み中...</p>;
  }

  return (
    <div className="App">
      <div className="header">
        <h1>出荷一覧</h1>
        <button
          className="register-button"
          onClick={() => navigate('/seimen/shops-out')}
        >
          出荷登録
        </button>
      </div>

      <div className="filter-container">
        <label>年:</label>
        <input type="text" name="year" value={filters.year} onChange={handleFilterChange} placeholder="例: 2024" />

        <label>月:</label>
        <input type="text" name="month" value={filters.month} onChange={handleFilterChange} placeholder="例: 01" />

        <label>期間: </label>
        <div className="date_length">
          <input type="date" name="from" value={filters.from} onChange={handleFilterChange} id='date'/>
          <span> 〜 </span>
          <input type="date" name="to" value={filters.to} onChange={handleFilterChange} id='date'/>
        </div>

        <button onClick={applyFilters}>フィルター適用</button>
      </div>

      {showDownloadButton && (
        <div className="download-container">
          <button onClick={downloadExcel}>ダウンロード</button>
        </div>
      )}

      {paginate(filteredShipments).length > 0 ? (
        <>
          <div className="table-container">
            <table className="shipments-table">
              <thead>
                <tr>
                  <th>日付</th>
                  <th>店舗</th>
                  <th>商品名</th>
                  <th>数量</th>
                  <th>単価</th>
                  <th>合計金額</th>
                  <th>操作</th>
                </tr>
              </thead>
              <tbody>
                {paginate(filteredShipments).map((shipment) => (
                  <tr key={shipment.id}>
                    <td>{shipment.date || '不明'}</td>
                    <td>{shipment.shopName || '不明'}</td>
                    <td>{shipment.productName || '不明'}</td>
                    <td>{shipment.quantity || 0}</td>
                    <td>¥{shipment.unitPrice?.toFixed(2) || '0.00'}</td>
                    <td>¥{shipment.totalCost?.toFixed(2) || '0.00'}</td>
                    <td>
                      <button
                        onClick={() => handleDelete(shipment.id)}
                        className="delete-button"
                      >
                        削除
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="pagination">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              前
            </button>
            <span>
              {currentPage} / {totalPages}
            </span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              次
            </button>
          </div>
        </>
      ) : (
        <p>該当する出荷データがありません。</p>
      )}
    </div>
  );
};

export default Shipments;
