import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, doc, deleteDoc, query, orderBy } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import '../App.css';
import * as XLSX from 'xlsx';

const InboundOrder = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [filters, setFilters] = useState({ year: '', month: '', from: '', to: '' });
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items per page
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      const q = query(collection(db, 'inbound_order'), orderBy('date', 'desc'));
      const querySnapshot = await getDocs(q);
      const ordersList = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setOrders(ordersList);
      setFilteredOrders(ordersList);
    };
    fetchOrders();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const applyFilters = () => {
    const { year, month, from, to } = filters;

    let filtered = [...orders];

    if (year) {
      filtered = filtered.filter((order) => order.date.startsWith(year));
    }

    if (month) {
      filtered = filtered.filter((order) => order.date.startsWith(`${year}-${month.padStart(2, '0')}`));
    }

    if (from && to) {
      filtered = filtered.filter((order) => order.date >= from && order.date <= to);
    }

    setFilteredOrders(filtered);
    setShowDownloadButton(true); // Show the download button after filtering
  };

  const downloadExcel = () => {
    if (filteredOrders.length === 0) {
      alert('データがありません');
      return;
    }

    const headers = ['日付け', '商品名', '単価', '数量 (単価)', '合計価格', '仕入先'];
    const data = [
      headers,
      ...filteredOrders.map((order) => [
        order.date || '未定',
        order.name || 'N/A',
        order.price ? `${parseFloat(order.price).toLocaleString()}円` : 'N/A',
        `${order.count || 0} (${order.price ? `${parseFloat(order.price).toLocaleString()}円` : 'N/A'})`,
        order.full_price ? `${parseFloat(order.full_price).toLocaleString()}円` : 0,
        order.company || 'N/A',
      ]),
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Filtered Orders');

    const today = new Date();
    const formattedDate = `${today.getFullYear()}${String(today.getMonth() + 1).padStart(2, '0')}${String(today.getDate()).padStart(2, '0')}`;
    const fileName = `FAC${formattedDate}.xlsx`;

    XLSX.writeFile(workbook, fileName);
  };

  const handleRegisterClick = () => {
    navigate('/seimen/order-register');
  };

  const handleDeleteClick = async (id) => {
    const confirmDelete = window.confirm('削除してもよろしいですか？');
    if (confirmDelete) {
      await deleteDoc(doc(db, 'inbound_order', id));
      setOrders(orders.filter((order) => order.id !== id));
      setFilteredOrders(filteredOrders.filter((order) => order.id !== id));
    }
  };

  const paginate = (data) => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return data.slice(indexOfFirstItem, indexOfLastItem);
  };

  const totalPages = () => Math.ceil(filteredOrders.length / itemsPerPage);

  const changePage = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages()) {
      setCurrentPage(pageNumber);
    }
  };

  return (
    <div className="App">
      <div className="header">
        <h1>入庫</h1>
        <button className="register-button" onClick={handleRegisterClick}>
          入庫登録
        </button>
      </div>

      <div className="filter-container">
        <label>年:</label>
        <input type="text" name="year" value={filters.year} onChange={handleFilterChange} placeholder="例: 2024" />

        <label>月:</label>
        <input type="text" name="month" value={filters.month} onChange={handleFilterChange} placeholder="例: 01" />

        <label>期間: </label>
        <div className="date_length">
          <input type="date" name="from" value={filters.from} onChange={handleFilterChange} id='date'/>
          <span> 〜 </span>
          <input type="date" name="to" value={filters.to} onChange={handleFilterChange} id='date'/>
        </div>

        <button onClick={applyFilters}>フィルター適用</button>
      </div>

      {showDownloadButton && (
        <div className="download-container">
          <button onClick={downloadExcel}>ダウンロード</button>
        </div>
      )}

      <div className="table-container">
        <table className="order-table">
          <thead>
            <tr>
              <th>日付け</th>
              <th>商品名</th>
              <th>単価</th>
              <th>数量</th>
              <th>合計価格</th>
              <th>仕入先</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            {paginate(filteredOrders).map((order) => (
              <tr key={order.id}>
                <td>{order.date || '未定'}</td>
                <td>{order.name || 'N/A'}</td>
                <td>{order.price ? parseFloat(order.price).toLocaleString() : 'N/A'}円</td>
                <td>
                  {order.count || 0}
                  <span>
                    ({parseFloat((order.count || 0) * (order.quantity || 0))}{order.unit || ''})
                  </span>
                </td>
                <td>{order.full_price ? parseFloat(order.full_price).toLocaleString() : 0}円</td>
                <td>{order.company || 'N/A'}</td>
                <td>
                  <button onClick={() => handleDeleteClick(order.id)} className="delete-button">
                    削除
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="pagination">
        <button onClick={() => changePage(currentPage - 1)}>前</button>
        <span>
          {currentPage} / {totalPages()}
        </span>
        <button onClick={() => changePage(currentPage + 1)}>次</button>
      </div>
    </div>
  );
};

export default InboundOrder;
