import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, onSnapshot, deleteDoc, doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import * as XLSX from 'xlsx';

const Loss = () => {
  const [lossInData, setLossInData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({ year: '', month: '', from: '', to: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10; // 1ページあたりの行数
  const navigate = useNavigate();
  const [showDownloadButton, setShowDownloadButton] = useState(false);

  useEffect(() => {
    const unsubscribeLossIn = onSnapshot(collection(db, 'loss_in'), (snapshot) => {
      const data = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setLossInData(data);
      setFilteredData(data);
    });

    return () => {
      unsubscribeLossIn();
    };
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm('このデータを削除してもよろしいですか？')) {
      try {
        await deleteDoc(doc(db, 'loss_in', id));
        alert('削除が完了しました。');
      } catch (error) {
        console.error('削除中にエラーが発生しました: ', error);
        alert('削除中にエラーが発生しました。');
      }
    }
  };

  const applyFilters = () => {
    const { year, month, from, to } = filters;

    let filtered = [...lossInData];

    if (year) {
      filtered = filtered.filter((loss) => loss.date.startsWith(year));
    }

    if (month) {
      filtered = filtered.filter((loss) => loss.date.startsWith(`${year}-${month.padStart(2, '0')}`));
    }

    if (from && to) {
      filtered = filtered.filter((loss) => loss.date >= from && loss.date <= to);
    }

    setFilteredData(filtered);
    setCurrentPage(1); // フィルター適用後、ページをリセット
    setShowDownloadButton(true);
  };

  const downloadExcel = () => {
    if (filteredData.length === 0) {
      alert('データがありません');
      return;
    }

    const headers = ['日付', '商品名', '数量', '合計価格'];
    const data = [
      headers,
      ...filteredData.map((loss) => [
        loss.date || '未定',
        loss.item || 'N/A',
        loss.quantity || 0,
        loss.totalPrice ? `¥${parseFloat(loss.totalPrice).toLocaleString()}` : '0',
      ]),
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Filtered Loss Data');

    const today = new Date();
    const formattedDate = `${today.getFullYear()}${String(today.getMonth() + 1).padStart(2, '0')}${String(today.getDate()).padStart(2, '0')}`;
    const fileName = `LossData_${formattedDate}.xlsx`;

    XLSX.writeFile(workbook, fileName);
  };

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const currentItems = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  return (
    <div className="App">
      <div className="header">
        <h1>廃棄管理</h1>
        <button
          className="register-button"
          onClick={() => navigate('/seimen/loss-in')}
        >
          入庫廃棄記録
        </button>
      </div>

      {/* フィルタセクション */}
      <div className="filter-container">
        <label>年:</label>
        <input
          type="text"
          name="year"
          value={filters.year}
          onChange={handleFilterChange}
          placeholder="例: 2024"
        />

        <label>月:</label>
        <input
          type="text"
          name="month"
          value={filters.month}
          onChange={handleFilterChange}
          placeholder="例: 01"
        />

        <label>期間:</label>
        <div className="date_length">
          <input
            type="date"
            name="from"
            value={filters.from}
            onChange={handleFilterChange}
            id='date'
          />
          <span> 〜 </span>
          <input
            type="date"
            name="to"
            value={filters.to}
            onChange={handleFilterChange}
            id='date'
          />
        </div>

        <button onClick={applyFilters}>フィルター適用</button>
        
      </div>

      {showDownloadButton && (
        <div className="download-container">
          <button onClick={downloadExcel}>ダウンロード</button>
        </div>
      )}

      <div className='table-container'>
        <table>
          <thead>
            <tr>
              <th>日付</th>
              <th>商品名</th>
              <th>数量</th>
              <th>合計価格</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((loss) => (
              <tr key={loss.id}>
                <td>{loss.date}</td>
                <td>{loss.item}</td>
                <td>{loss.quantity}</td>
                <td>¥{loss.totalPrice.toFixed(2)}</td>
                <td>
                  <button
                    className="delete-button"
                    onClick={() => handleDelete(loss.id)}
                  >
                    削除
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      

      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          前
        </button>
        <span>
          {currentPage} / {totalPages}
        </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          次
        </button>
      </div>
    </div>
  );
};

export default Loss;
