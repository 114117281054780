import React, { useEffect, useState } from 'react';
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import * as XLSX from 'xlsx';

const Outbound = () => {
  const [outboundData, setOutboundData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({ year: '', month: '', from: '', to: '' });
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10; // 1ページあたりのデータ数
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOutboundData = async () => {
      setLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, 'out_stock'));
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
  
        console.log('Fetched Outbound Data:', data); // Debugging log
        const sortedData = data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setOutboundData(sortedData);
        setFilteredData(sortedData);
      } catch (error) {
        console.error('Error fetching outbound data: ', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchOutboundData();
  }, []);
  

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const applyFilters = () => {
    const { year, month, from, to } = filters;

    let filtered = [...outboundData];

    if (year) {
      filtered = filtered.filter((record) => record.date?.startsWith(year));
    }

    if (month) {
      filtered = filtered.filter((record) => record.date?.startsWith(`${year}-${month.padStart(2, '0')}`));
    }

    if (from && to) {
      filtered = filtered.filter((record) => record.date >= from && record.date <= to);
    }

    setFilteredData(filtered);
    setShowDownloadButton(true);
    setCurrentPage(1); // フィルター適用後、ページをリセット
  };

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const currentItems = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('このレコードを削除しますか？')) {
      try {
        await deleteDoc(doc(db, 'out_stock', id));
        setOutboundData((prev) => prev.filter((record) => record.id !== id));
        setFilteredData((prev) => prev.filter((record) => record.id !== id));
        alert('レコードが削除されました。');
      } catch (error) {
        console.error('Error deleting record:', error);
        alert('レコードの削除に失敗しました。');
      }
    }
  };

  const downloadExcel = () => {
    if (filteredData.length === 0) {
      alert('データがありません');
      return;
    }

    const headers = ['日付', '合計金額', '商品名', '数量', '単価', '商品合計価格'];
    const excelData = [
      headers,
      ...filteredData.flatMap((record) =>
        (record.items || []).map((item) => [
          record.date || '不明',
          parseFloat(record.totalCost || 0).toLocaleString(),
          item.recipeName || '不明',
          item.outputQuantity || 0,
          item.unitPrice?.toFixed(2) || '0.00',
          (item.unitPrice * item.outputQuantity)?.toFixed(2) || '0.00',
        ])
      ),
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Outbound Data');

    const today = new Date();
    const formattedDate = `${today.getFullYear()}${String(today.getMonth() + 1).padStart(2, '0')}${String(today.getDate()).padStart(2, '0')}`;
    const fileName = `Outbound_${formattedDate}.xlsx`;

    XLSX.writeFile(workbook, fileName);
  };

  return (
    <div className="App">
      <div className="header">
        <h1>仕入一覧</h1>
        <button className="register-button" onClick={() => navigate('/seimen/outbound-register')}>
          仕入登録
        </button>
      </div>

      <div className="filter-container">
        <label>年:</label>
        <input type="text" name="year" value={filters.year} onChange={handleFilterChange} placeholder="例: 2024" />

        <label>月:</label>
        <input type="text" name="month" value={filters.month} onChange={handleFilterChange} placeholder="例: 01" />

        <label>期間:</label>
        <div className="date_length">
          <input type="date" name="from" value={filters.from} onChange={handleFilterChange} id="date"/>
          <span> 〜 </span>
          <input type="date" name="to" value={filters.to} onChange={handleFilterChange} id="date"/>
        </div>

        <button onClick={applyFilters}>フィルター適用</button>
      </div>

      {showDownloadButton && (
        <div className="download-container">
          <button onClick={downloadExcel}>ダウンロード</button>
        </div>
      )}

      {loading ? (
        <p>データを読み込んでいます...</p>
      ) : currentItems.length > 0 ? (
        <div className="table-container">
          <table className="outbound-table">
            <thead>
              <tr>
                <th>日付</th>
                <th>合計金額</th>
                <th>商品名</th>
                <th>数量</th>
                <th>単価</th>
                <th>商品合計価格</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((outbound) =>
                (outbound.items || []).map((item, index) => (
                  <tr key={`${outbound.id}-${index}`}>
                    <td>{index === 0 ? outbound.date || '不明' : ''}</td>
                    <td>{index === 0 ? `¥${parseFloat(outbound.totalCost || 0).toLocaleString()}` : ''}</td>
                    <td>{item.recipeName || '不明'}</td>
                    <td>{item.outputQuantity || 0}</td>
                    <td>¥{parseFloat(item.unitPrice || 0).toFixed(2)}</td>
                    <td>¥{(item.unitPrice * item.outputQuantity)?.toFixed(2)}</td>
                    <td>
                      {index === 0 && (
                        <button onClick={() => handleDelete(outbound.id)} className="delete-button">
                          削除
                        </button>
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>

          </table>

          <div className="pagination">
            <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
              前
            </button>
            <span>
              {currentPage} / {totalPages}
            </span>
            <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
              次
            </button>
          </div>
        </div>
      ) : (
        <p>表示するデータがありません。</p>
      )}
    </div>
  );
};

export default Outbound;
