import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import Dashboard from './pages/Dashboard';
import Inbound from './pages/Inbound';
import Outbound from './pages/Outbound';
import Recipe from './pages/Recipe';
import Loss from './pages/Loss';
import InboundOrder from './pages/InboundOrder';
import InboundRegister from './pages/InboundRegister';
import InboundOrderRegister from './pages/InboundOrderRegister';
import RecipeRegister from './pages/RecipeRegister';
import RecipeView from './pages/RecipeView';
import OutboundRegister from './pages/OutboundRegister';
import ShopOut from './pages/ShopOut';
import ShopManage from './pages/ShopManage';
import Shipments from './pages/Shipments';
import ShopRegister from './pages/ShopRegister';
import ShopEdit from './pages/ShopEdit';
import InboundEdit from './pages/InboundEdit';
import OutboundItemRegister from './pages/OutboundItemRegister';
import InboundShopRegister from './pages/InboundShopRegister';
import InboundShopManage from './pages/InboundShopManage';
import InboundShopEdit from './pages/InboundShopEdit';
import LossIn from './pages/LossIn';
import LossOut from './pages/LossOut';
import Sample from './pages/sample';
import SignUp from './pages/SignUp';

import DrawerMenu from './components/DrawerMenu';
import Login from './pages/Login';

import { AuthProvider, useAuth } from './AuthContext';
import ProtectedRoute from './ProtectedRoute';

function PublicRoute({ children }) {
  const { user } = useAuth();
  return !user ? children : <Navigate to="/seimen/" />;
}

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          {/* 未ログインでもアクセス可能なページ */}
          <Route path="/seimen/login" element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          } />
          <Route path="/seimen/signup" element={
            <PublicRoute>
              <SignUp />
            </PublicRoute>
          } />

          {/* ログイン後の保護されたページ群 */}
          <Route path="/seimen/*" element={
            <ProtectedRoute>
              <DrawerMenu>
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="inbound" element={<Inbound />} />
                  <Route path="inbound-order" element={<InboundOrder />} />
                  <Route path="inbound-register" element={<InboundRegister />} />
                  <Route path="order-register" element={<InboundOrderRegister />} />
                  <Route path="recipe-register" element={<RecipeRegister />} />
                  <Route path="recipe-view/:recipeId" element={<RecipeView />} />
                  <Route path="outbound" element={<Outbound />} />
                  <Route path="outbound-register" element={<OutboundRegister />} />
                  <Route path="shops-out" element={<ShopOut />} />
                  <Route path="shops-register" element={<ShopRegister />} />
                  <Route path="shipments" element={<Shipments />} />
                  <Route path="shop-manage" element={<ShopManage />} />
                  <Route path="recipe" element={<Recipe />} />
                  <Route path="loss" element={<Loss />} />
                  <Route path="loss-in" element={<LossIn />} />
                  <Route path="loss-out" element={<LossOut />} />
                  <Route path="shops-edit/:id" element={<ShopEdit />} />
                  <Route path="inbound-edit/:id" element={<InboundEdit />} />
                  <Route path="outbound-item-register" element={<OutboundItemRegister />} />
                  <Route path="inbound-shops-register" element={<InboundShopRegister />} />
                  <Route path="inbound-shop-manage" element={<InboundShopManage />} />
                  <Route path="inbound-shops-edit/:id" element={<InboundShopEdit />} />
                  <Route path="sample" element={<Sample />} />
                </Routes>
              </DrawerMenu>
            </ProtectedRoute>
          } />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
