import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, addDoc } from 'firebase/firestore';
import '../App.css';
import { useNavigate } from 'react-router-dom';

const LossOut = () => {
  const [losses, setLosses] = useState([{ item: '', quantity: '', unitPrice: 0, totalPrice: 0, unit: '' }]);
  const [recipeItems, setRecipeItems] = useState([]);
  const [date, setDate] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRecipeItems = async () => {
      const querySnapshot = await getDocs(collection(db, 'recipes'));
      setRecipeItems(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    fetchRecipeItems();
  }, []);

  const handleItemChange = (index, field, value) => {
    const updatedLosses = [...losses];
    const loss = updatedLosses[index];

    if (field === 'item') {
      const selectedItem = recipeItems.find((item) => item.name === value);
      if (selectedItem) {
        const pricePerGramOrLiter =
          selectedItem.unit === 'g'
            ? selectedItem.price / selectedItem.quantity
            : selectedItem.unit === 'kg'
            ? selectedItem.price / (selectedItem.quantity * 1000)
            : selectedItem.unit === 'ml'
            ? selectedItem.price / selectedItem.quantity
            : selectedItem.unit === 'l'
            ? selectedItem.price / (selectedItem.quantity * 1000)
            : selectedItem.price || 0;

        loss.unitPrice = parseFloat(pricePerGramOrLiter) || 0; // Ensure unit price is valid
        loss.unit = selectedItem.unit || ''; // Store the unit for display
      } else {
        loss.unitPrice = 0;
        loss.unit = ''; // Reset if no valid item
      }
      loss.item = value;
    }

    if (field === 'quantity') {
      const quantity = parseFloat(value) || 0;
      loss.quantity = quantity;
      loss.totalPrice = quantity * (loss.unitPrice || 0);
    }

    updatedLosses[index] = loss;
    setLosses(updatedLosses);
  };

  const addRow = () => {
    setLosses([...losses, { item: '', quantity: '', unitPrice: 0, totalPrice: 0, unit: '' }]);
  };

  const removeRow = (index) => {
    const updatedLosses = losses.filter((_, i) => i !== index);
    setLosses(updatedLosses);
  };

  const recordLosses = async () => {
    if (!date) {
      alert('日付を入力してください。');
      return;
    }

    const validLosses = losses.filter((loss) => loss.item && loss.quantity > 0);

    if (validLosses.length === 0) {
      alert('少なくとも1つの有効な廃棄項目を入力してください。');
      return;
    }

    try {
      await Promise.all(
        validLosses.map((loss) =>
          addDoc(collection(db, 'loss_out'), {
            date,
            item: loss.item,
            quantity: parseFloat(loss.quantity),
            unitPrice: parseFloat(loss.unitPrice.toFixed(2)),
            totalPrice: parseFloat(loss.totalPrice.toFixed(2)),
            unit: loss.unit,
          })
        )
      );

      alert('廃棄記録が保存されました。');
      setLosses([{ item: '', quantity: '', unitPrice: 0, totalPrice: 0, unit: '' }]);
      setDate('');
      navigate('/seimen/loss');
    } catch (error) {
      console.error('Error saving loss records:', error);
      alert('記録中にエラーが発生しました。');
    }
  };

  const getAvailableItems = (currentIndex) => {
    const selectedItems = losses.map((loss) => loss.item);
    return recipeItems.filter(
      (item) => !selectedItems.includes(item.name) || item.name === losses[currentIndex].item
    );
  };

  return (
    <div className="App">
      <h1>出庫廃棄記録</h1>
      <label>
        日付:
        <input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
      </label>
      <table className="loss-table">
        <thead>
          <tr>
            <th>商品名</th>
            <th>数量</th>
            <th>単位</th>
            <th>単価 (¥/g)</th>
            <th>合計価格 (¥)</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          {losses.map((loss, index) => (
            <tr key={index}>
              <td>
                <select
                  value={loss.item}
                  onChange={(e) => handleItemChange(index, 'item', e.target.value)}
                >
                  <option value="">商品を選択</option>
                  {getAvailableItems(index).map((item) => (
                    <option key={item.id} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <input
                  type="number"
                  value={loss.quantity}
                  onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                  placeholder="数量"
                />
              </td>
              <td>{loss.unit}</td>
              <td>¥{loss.unitPrice.toFixed(2)}</td>
              <td>¥{loss.totalPrice.toFixed(2)}</td>
              <td>
                <button onClick={() => removeRow(index)}>削除</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={addRow}>行を追加</button>
      <button onClick={recordLosses}>記録</button>
    </div>
  );
};

export default LossOut;
