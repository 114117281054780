import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, addDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import '../App.css';

const Sample = () => {
  return (
    <div className="App InboundRegisterApp">
      <h1>アプリの使い方</h1>

      <div className="image-container">
        <img src="https://darumaya-group.jp/wp/wp-content/uploads/2024/12/usage.png" alt="説明画像" />
      </div>
    </div>
  );
};

export default Sample;