import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, onSnapshot, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import '../App.css';

const Dashboard = () => {
  const [stockData, setStockData] = useState([]);
  const [productionData, setProductionData] = useState([]);
  const [currentPageStock, setCurrentPageStock] = useState(1);
  const [currentPageProduction, setCurrentPageProduction] = useState(1);
  const [searchTermStock, setSearchTermStock] = useState('');
  const [searchTermProduction, setSearchTermProduction] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [editingItems, setEditingItems] = useState({});
  const itemsPerPage = 10;
  // const [outStockData, setOutStockData] = useState([]);
  const [yesterdayOutStock, setYesterdayOutStock] = useState({});
  const [todayOutStock, setTodayOutStock] = useState({});
  

  useEffect(() => {
    const unsubscribeStock = onSnapshot(collection(db, 'stock'), (snapshot) => {
      setStockData(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    });

    const unsubscribeProduction = onSnapshot(collection(db, 'production'), (snapshot) => {
      setProductionData(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    });


    const unsubscribeOutStock = onSnapshot(collection(db, 'out_stock'), (snapshot) => {
      const data = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      const today = new Date();
      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      console.log(yesterday);
    
      const formatDate = (date) =>
        `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    
      const formattedToday = formatDate(today);
      const formattedYesterday = formatDate(yesterday);
    
      const todayData = data.filter((item) => item.date === formattedToday);
      const yesterdayData = data.filter((item) => item.date === formattedYesterday);
    
      const mergeDataByName = (items) => {
        const mergedData = {};
        items.forEach((doc) => {
          if (doc.items && Array.isArray(doc.items)) {
            doc.items.forEach((item) => {
              if (item.recipeName) {
                if (!mergedData[item.recipeName]) {
                  mergedData[item.recipeName] = parseFloat(item.outputQuantity || 0);
                } else {
                  mergedData[item.recipeName] += parseFloat(item.outputQuantity || 0);
                }
              }
            });
          }
        });
        return mergedData;
      };
    
      // setOutStockData(data);
      setTodayOutStock(mergeDataByName(todayData));
      setYesterdayOutStock(mergeDataByName(yesterdayData));
    });
    


    return () => {
      unsubscribeStock();
      unsubscribeProduction();
      unsubscribeOutStock();
    };
  }, []);

  const toggleEditMode = () => {
    setEditMode(!editMode);
    if (!editMode) {
      const editingStock = stockData.reduce((acc, item) => {
        acc[item.id] = item.quantity;
        return acc;
      }, {});

      const editingProduction = productionData.reduce((acc, item) => {
        acc[item.id] = item.productionQuantity;
        return acc;
      }, {});

      setEditingItems({ ...editingStock, ...editingProduction });
    } else {
      setEditingItems({});
    }
  };

  const handleQuantityChange = (id, value) => {
    setEditingItems({
      ...editingItems,
      [id]: value,
    });
  };

  const saveChanges = async (id, collectionName) => {
    const updatedQuantity = parseFloat(editingItems[id]);
    if (isNaN(updatedQuantity) || updatedQuantity < 0) {
      alert('有効な数量を入力してください。');
      return;
    }

    try {
      const docRef = doc(db, collectionName, id);
      await updateDoc(docRef, {
        productionQuantity: updatedQuantity,
      });

      alert('数量が更新されました！');
      setEditMode(false);
    } catch (error) {
      console.error('Error updating quantity:', error);
      alert('更新中にエラーが発生しました。');
    }
  };

  const deleteItem = async (id, collectionName) => {
    if (window.confirm('本当に削除しますか？')) {
      try {
        await deleteDoc(doc(db, collectionName, id));
        alert('項目が削除されました。');
      } catch (error) {
        console.error('Error deleting item:', error);
        alert('削除中にエラーが発生しました。');
      }
    }
  };

  const filteredStockData = stockData.filter((stock) =>
    stock.name.toLowerCase().includes(searchTermStock.toLowerCase())
  );

  const filteredProductionData = productionData.filter((production) =>
    production.recipeName.toLowerCase().includes(searchTermProduction.toLowerCase())
  );

  const totalPagesStock = Math.ceil(filteredStockData.length / itemsPerPage);
  const currentStockData = filteredStockData.slice(
    (currentPageStock - 1) * itemsPerPage,
    currentPageStock * itemsPerPage
  );

  const totalPagesProduction = Math.ceil(filteredProductionData.length / itemsPerPage);
  const currentProductionData = filteredProductionData.slice(
    (currentPageProduction - 1) * itemsPerPage,
    currentPageProduction * itemsPerPage
  );




  const handleStockPageChange = (pageNumber) => {
    setCurrentPageStock(pageNumber);
  };

  const handleProductionPageChange = (pageNumber) => {
    setCurrentPageProduction(pageNumber);
  };

  return (
    <div className="App">
      <div className="dashboard-header">
        <h1>在庫リスト</h1>
        <div className="toggle-edit-container">
          <button className="toggle-edit-button" onClick={toggleEditMode}>
            {editMode ? '終了' : '操作'}
          </button>
        </div>

        <div className="search_bar_sec">
          <input
            type="text"
            placeholder="商品検索..."
            value={searchTermStock}
            onChange={(e) => setSearchTermStock(e.target.value)}
            id="search-bar"
          />
          <h2>入庫</h2>
        </div>
      </div>

      <div className="stock-table-container">
        <table className="stock-table">
          <thead>
            <tr>
              <th className='dashTable_th'>商品名</th>
              <th className='dashTable_th'>数量</th>
              {editMode && <th className="dashboard_button">操作</th>}
            </tr>
          </thead>
          <tbody>
            {currentStockData.map((stock) => (
              <tr key={stock.id}>
                <td className="stock-name">{stock.name}</td>
                <td className="stock-quantity">
                  {editMode ? (
                    <input
                      type="number"
                      value={editingItems[stock.id] || ''}
                      onChange={(e) => handleQuantityChange(stock.id, e.target.value)}
                    />
                  ) : (
                    `${parseFloat(stock.quantity).toFixed(1)} ${stock.unit}`
                  )}
                </td>
                {editMode && (
                  <td className="dashboard_button">
                    <button className="edit-button" onClick={() => saveChanges(stock.id, 'stock')}>
                      保存
                    </button>
                    <button className="delete-button" onClick={() => deleteItem(stock.id, 'stock')}>
                      削除
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="pagination1">
        {Array.from({ length: totalPagesStock }, (_, index) => (
          <button
            key={index}
            className={`page-button ${currentPageStock === index + 1 ? 'active' : ''}`}
            onClick={() => handleStockPageChange(index + 1)}
          >
            {index + 1}
          </button>
        ))}
      </div>

    
      <div className="saparate_bar"></div>

      <div className="search_bar_sec">
        <input
          type="text"
          placeholder="商品検索..."
          value={searchTermProduction}
          onChange={(e) => setSearchTermProduction(e.target.value)}
          id="search-bar"
        />
        <h2>出庫</h2>
      </div>

      <div className="stock-table-container">
        <table className="stock-table">
          <thead>
            <tr>
              <th className='dashTable_th'>商品名</th>
              <th className='dashTable_th'>数量</th>
              {editMode && <th className="dashboard_button">操作</th>}
            </tr>
          </thead>
          <tbody>
            {currentProductionData.map((production) => (
              <tr key={production.id}>
                <td className="stock-name">{production.recipeName}</td>
                <td className="stock-quantity">
                  {editMode ? (
                    <input
                      type="number"
                      value={editingItems[production.id] || ''}
                      onChange={(e) => handleQuantityChange(production.id, e.target.value)}
                    />
                  ) : (
                    `${parseFloat(production.productionQuantity).toFixed(0)} ${production.unit}`
                  )}
                </td>
                {editMode && (
                  <td className="dashboard_button">
                    <button
                      className="edit-button"
                      onClick={() => saveChanges(production.id, 'production')}
                    >
                      保存
                    </button>
                    <button
                      className="delete-button"
                      onClick={() => deleteItem(production.id, 'production')}
                    >
                      削除
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="pagination1">
        {Array.from({ length: totalPagesProduction }, (_, index) => (
          <button
            key={index}
            className={`page-button ${currentPageProduction === index + 1 ? 'active' : ''}`}
            onClick={() => handleProductionPageChange(index + 1)}
          >
            {index + 1}
          </button>
        ))}
      </div>

      <div className="saparate_bar"></div>



      <h2>仕入れデータ (昨日・今日)</h2>
        <table className="summary-table">
          <thead>
            <tr>
              <th className='dashTable_th'>商品名</th>
              <th className='dashTable_th'>昨日の数量</th>
              <th className='dashTable_th'>今日の数量</th>
            </tr>
          </thead>
          <tbody>
            {[
              ...new Set([
                ...Object.keys(yesterdayOutStock),
                ...Object.keys(todayOutStock),
              ]),
            ].map((name) => (
              <tr key={name}>
                <td>{name}</td>
                <td className='dashTable_th'>{yesterdayOutStock[name]?.toFixed(0) || '0'}</td>
                <td className='dashTable_th'>{todayOutStock[name]?.toFixed(0) || '0'}</td>
              </tr>
            ))}
          </tbody>
        </table>

      <div className="saparate_bar"></div>



{/* こちらにテーブルを追加したい
firebaseの
  shipments-
    DocId-
      [createdAt,
        date,
        productName,
        quantity,
        shopName,
        totalCost,
        unitPrice,
      ]


このデータを使って
      coloumごとに”shopName”で
      全店舗の昨日と今日のデータを一つのテーブルにしたい */}


      


    </div>
  );
};

export default Dashboard;
