import React, { useState } from 'react';
import { db } from '../firebase';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import '../Pages.css';

const OutboundItemRegister = () => {
  const [item, setItem] = useState({
    code: '',
    name: '',
    category: '',
    specification: '',
    quantity: '',
    unit: '',
    price: '',
    supplier: '',
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Prevent negative input for quantity and price
    if ((name === 'quantity' || name === 'price') && value < 0) {
      setErrors({ ...errors, [name]: 'マイナス値は入力できません。' });
      return;
    }

    setItem({ ...item, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const validate = () => {
    let tempErrors = {};
    if (!item.code) tempErrors.code = "商品コードは必須です。";
    if (!item.name) tempErrors.name = "商品名は必須です。";
    if (!item.quantity) tempErrors.quantity = "出荷数は必須です。";
    if (!item.unit) tempErrors.unit = "単位は必須です。";
    if (!item.price) tempErrors.price = "価格は必須です。";
    setErrors(tempErrors);
    if (Object.keys(tempErrors).length > 0) {
      window.alert(Object.values(tempErrors).join('\n'));
    }
    return Object.keys(tempErrors).length === 0;
  };

  const addItem = async () => {
    if (validate()) {
      setLoading(true);
      try {
        // Check if the product code already exists
        const q = query(collection(db, 'outbound_items'), where('code', '==', item.code));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          window.alert("この商品コードは既に存在します。別のコードを入力してください。");
        } else {
          await addDoc(collection(db, 'outbound_items'), item);
          setItem({
            code: '',
            name: '',
            category: '',
            specification: '',
            quantity: '',
            unit: '',
            price: '',
            supplier: '',
          });
          navigate('/seimen/outbound');
        }
      } catch (error) {
        console.error("Error adding document: ", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCancel = () => {
    navigate('/seimen/outbound');
  };

  return (
    <div className="OutboundRegisterApp">
      <h1>新規出荷商品登録</h1>
      <div className="OutboundRegisterFormGroup">
        <label className='sp_label'>商品コード<span>(必須)</span></label>
        <input name="code" type="text" value={item.code} onChange={handleChange} />
      </div>
      <div className="OutboundRegisterFormGroup">
        <label className='sp_label'>商品名<span>(必須)</span></label>
        <input name="name" type="text" value={item.name} onChange={handleChange} />
      </div>
      <div className="OutboundRegisterFormGroup">
        <label className='sp_label'>カテゴリ</label>
        <input name="category" type="text" value={item.category} onChange={handleChange} />
      </div>
      <div className="OutboundRegisterFormGroup">
        <label className='sp_label'>規格</label>
        <input name="specification" type="text" value={item.specification} onChange={handleChange} />
      </div>
      <div className="OutboundRegisterFormGroup">
        <label className='sp_label'>出荷数／単位<span>(必須)</span></label>
        <input name="quantity" type="number" min="0" value={item.quantity} onChange={handleChange} />
        <select name="unit" value={item.unit} onChange={handleChange}>
          <option value="">単位</option>
          <option value="g">g</option>
          <option value="kg">kg</option>
          <option value="ml">ml</option>
          <option value="L">L</option>
          <option value="個">個</option>
          <option value="バット">バット</option>
          <option value="袋">袋</option>
          <option value="玉">玉</option>
        </select>
      </div>
      <div className="OutboundRegisterFormGroup">
        <label className='sp_label'>価格<span>(必須)</span></label>
        <input name="price" type="number" min="0" value={item.price} onChange={handleChange} />
      </div>
      <div className="OutboundRegisterFormGroup">
        <label className='sp_label'>仕入先</label>
        <input name="supplier" type="text" value={item.supplier} onChange={handleChange} />
      </div>
      <div className="OutboundRegisterButtons">
        <button className="OutboundRegisterSubmitButton" onClick={addItem} disabled={loading}>
          {loading ? "登録中..." : "出荷商品登録"}
        </button>
        <button className="OutboundRegisterCancelButton" onClick={handleCancel} disabled={loading}>
          キャンセル
        </button>
      </div>
    </div>
  );
};

export default OutboundItemRegister;
