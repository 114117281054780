import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { signOut } from 'firebase/auth';
import { auth, db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../AuthContext'; // ログイン中ユーザー取得
import './DrawerMenu.css';

const DrawerMenu = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [weather, setWeather] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const { user } = useAuth(); // uidを含むログインユーザー
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const fetchWeather = async (latitude, longitude) => {
      try {
        const response = await axios.get(
          `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&units=metric&appid=f99b5344c934c557101684b1ad68cccc`
        );
        setWeather({
          temperature: response.data.main.temp,
          icon: `http://openweathermap.org/img/wn/${response.data.weather[0].icon}.png`,
          description: response.data.weather[0].description,
        });
      } catch (error) {
        console.error("天気情報の取得に失敗しました:", error.message);
      }
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => fetchWeather(coords.latitude, coords.longitude),
        (error) => console.error("位置情報の取得に失敗しました:", error)
      );
    }
  }, []);

  // 🔄 Firestore からユーザー情報を取得
  useEffect(() => {
    const fetchUserInfo = async () => {
      if (user?.uid) {
        const userDoc = doc(db, 'users', user.uid); // UID をドキュメントIDとして使っている場合
        const snapshot = await getDoc(userDoc);
        if (snapshot.exists()) {
          setUserInfo(snapshot.data());
        }
      }
    };
    fetchUserInfo();
  }, [user]);

  const handleLogout = async () => {
    const confirm = window.confirm('ログアウトしますか？');
    if (confirm) {
      await signOut(auth);
      navigate('/seimen/login');
    }
  };

  return (
    <div className={`drawer-menu ${open ? 'open' : ''}`}>
      <header className="app-bar">
        <button className="menu-button" onClick={handleDrawerToggle}>
          <span className="menu-icon">&#9776;</span>
        </button>
        <h2 className="app-title">亀田製麺所</h2>
        {weather && (
          <div className="weather-info">
            <img src={weather.icon} alt={weather.description} className="weather-icon" />
            <span className="temperature">{weather.temperature}°C</span>
          </div>
        )}
      </header>

      <nav className={`drawer ${open ? 'open' : ''}`}>
        <div className="drawer-content">
          {userInfo && (
            <div className="user-info">
              <div>
                <strong>{userInfo.name}</strong><br />
                社員番号: {userInfo.employeeId}
              </div>
              <button className="logout-button" onClick={handleLogout}>
                ログアウト
              </button>
            </div>
          )}
   

          <ul>
            <li><Link to="/seimen/" onClick={handleDrawerToggle}>ダッシュボード</Link></li>
            <li><Link to="/seimen/outbound" onClick={handleDrawerToggle}>仕入れ</Link></li>
            <li><Link to="/seimen/inbound" onClick={handleDrawerToggle}>入庫商品</Link></li>
            <li><Link to="/seimen/inbound-order" onClick={handleDrawerToggle}>入庫</Link></li>
            <li><Link to="/seimen/inbound-shop-manage" onClick={handleDrawerToggle}>入庫先</Link></li>
            <li><Link to="/seimen/recipe" onClick={handleDrawerToggle}>出庫商品 (レシピ)</Link></li>
            <li><Link to="/seimen/shipments" onClick={handleDrawerToggle}>出庫</Link></li>
            <li><Link to="/seimen/shop-manage" onClick={handleDrawerToggle}>出庫先管理</Link></li>
            <li><Link to="/seimen/loss" onClick={handleDrawerToggle}>廃棄履歴</Link></li>
            <li><Link to="/seimen/sample" onClick={handleDrawerToggle}>アプリ使い方</Link></li>
          </ul>
        </div>

        <div className="drawer-footer">
          <button className="menu-button" onClick={handleDrawerToggle}>
            <span className="close-icon">&times;</span>
          </button>
        </div>
      </nav>

      <main className="main-content">
        {children}
      </main>
    </div>
  );
};

export default DrawerMenu;
