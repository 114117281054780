import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { doc, getDoc, updateDoc, deleteDoc, collection, getDocs } from 'firebase/firestore';
import { useParams, useNavigate } from 'react-router-dom';
import '../App.css';

const RecipeView = () => {
  const { recipeId } = useParams(); // Get the recipe ID from the URL
  const [recipe, setRecipe] = useState(null);
  const [availableProducts, setAvailableProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRecipe = async () => {
      try {
        const recipeRef = doc(db, 'recipes', recipeId);
        const recipeDoc = await getDoc(recipeRef);

        if (recipeDoc.exists()) {
          setRecipe({ id: recipeDoc.id, ...recipeDoc.data() });
        } else {
          alert('レシピが見つかりませんでした。');
          navigate('/seimen/recipe');
        }
      } catch (error) {
        console.error('Error fetching recipe: ', error);
      }
    };

    fetchRecipe();
  }, [recipeId, navigate]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'inbound'));
        const productsList = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setAvailableProducts(productsList);
      } catch (error) {
        console.error('Error fetching products: ', error);
      }
    };

    fetchProducts();
  }, []);

  const handleChange = (field, value) => {
    setRecipe((prevRecipe) => ({ ...prevRecipe, [field]: value }));
  };

  const handleAddIngredient = () => {
    const newIngredients = [...recipe.ingredients, { code : '', name: '', quantity: '', cost: 0, unit: 'g' }];
    handleChange('ingredients', newIngredients);
  };

  const handleIngredientSelect = (index, value) => {
    const selectedProduct = availableProducts.find((product) => product.name === value);
    if (selectedProduct) {
      const newIngredients = [...recipe.ingredients];
      newIngredients[index] = {
        code: selectedProduct.code,
        name: selectedProduct.name,
        quantity: newIngredients[index].quantity || '',
        unit: newIngredients[index].unit || 'g',
        cost: newIngredients[index].quantity
          ? calculateCost(selectedProduct, newIngredients[index].quantity)
          : 0,
      };
      handleChange('ingredients', newIngredients);
    }
  };

  const handleQuantityChange = (index, quantity) => {
    const newIngredients = [...recipe.ingredients];
    newIngredients[index].quantity = quantity;

    const selectedProduct = availableProducts.find((product) => product.name === newIngredients[index].name);
    if (selectedProduct) {
      newIngredients[index].cost = calculateCost(selectedProduct, quantity);
    }
    handleChange('ingredients', newIngredients);
  };

  const calculateCost = (product, quantity) => {
    let productWeightInGrams = product.quantity;

    switch (product.unit) {
      case 'kg':
        productWeightInGrams *= 1000;
        break;
      case 'L':
        productWeightInGrams *= 1000;
        break;
      default:
        break;
    }

    const pricePerGram = product.price / productWeightInGrams;
    const quantityInGrams = product.unit === 'kg' ? quantity * 1000 : quantity;

    return quantityInGrams * pricePerGram;
  };

  const calculateTotalCost = () => {
    return recipe.ingredients.reduce((total, ingredient) => total + (ingredient.cost || 0), 0);
  };

  const handleSave = async () => {
    if (!recipe.name || recipe.ingredients.some((ingredient) => !ingredient.name || !ingredient.quantity)) {
      alert('レシピ名とすべての原材料情報を入力してください。');
      return;
    }

    setLoading(true);
    try {
      const recipeRef = doc(db, 'recipes', recipeId);
      await updateDoc(recipeRef, {
        code: recipe.code,
        name: recipe.name,
        ingredients: recipe.ingredients,
        productionQuantity: recipe.productionQuantity,
        unit: recipe.unit,
        totalCost: calculateTotalCost(),
      });
      alert('レシピが更新されました。');
      navigate('/seimen/recipe');
    } catch (error) {
      console.error('Error updating document: ', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    const confirmDelete = window.confirm('このレシピを削除してもよろしいですか？');
    if (confirmDelete) {
      try {
        const recipeRef = doc(db, 'recipes', recipeId);
        await deleteDoc(recipeRef);
        alert('レシピが削除されました。');
        navigate('/seimen/recipe');
      } catch (error) {
        console.error('Error deleting document: ', error);
      }
    }
  };

  if (!recipe) return <div>読み込み中...</div>;

  const selectedIngredientNames = recipe.ingredients.map((ingredient) => ingredient.name);

  const formatCurrency = (amount) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const totalCost = calculateTotalCost();
  const formattedTotalCost = formatCurrency(totalCost.toFixed(0));

  return (
    <div className="App InboundRegisterApp">
      <h1>レシピ修正</h1>

      <div className="RecipeRegisterFormGroup">
        <label>
          レシピ番号<span className="required"> (必須)</span>
        </label>
        <input
          type="text"
          value={recipe.code || ''}
          onChange={(e) => handleChange('code', e.target.value)}
          placeholder="レシピコード"
        />
      </div>

      <div className="RecipeFormGroup">
        <label>レシピ名</label>
        <input
          type="text"
          value={recipe.name || ''}
          onChange={(e) => handleChange('name', e.target.value)}
        />
      </div>

      <div className="table-container">
        <table className="RecipeRegisterTable">
          <thead>
            <tr>
              <th>#</th>
              <th>原材料</th>
              <th>使用量</th>
              <th>原価</th>
              <th>削除</th>
            </tr>
          </thead>
          <tbody>
            {recipe.ingredients.map((ingredient, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  <select
                    value={ingredient.name || ''}
                    onChange={(e) => handleIngredientSelect(index, e.target.value)}
                  >
                    <option value="">原材料を選択してください</option>
                    {availableProducts
                      .filter(
                        (product) =>
                          !selectedIngredientNames.includes(product.name) || product.name === ingredient.name
                      )
                      .map((product) => (
                        <option key={product.id} value={product.name}>
                          {product.name}
                        </option>
                      ))}
                  </select>
                </td>
                <td>
                  <input
                    type="number"
                    value={ingredient.quantity || ''}
                    onChange={(e) => handleQuantityChange(index, e.target.value)}
                    placeholder="使用量"
                  />
                </td>
                <td>{`¥${(ingredient.cost || 0).toFixed(0)}`}</td>
                <td>
                  <button
                    className="RecipeRegisterDeleteButtonSub"
                    onClick={() => {
                      const newIngredients = recipe.ingredients.filter((_, i) => i !== index);
                      handleChange('ingredients', newIngredients);
                    }}
                  >
                    削除
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <button className="RecipeRegisterAddButton" onClick={handleAddIngredient}>
        原材料を追加
      </button>
      <div className="total-box">
        <p>
          合計金額: <span>¥{formattedTotalCost}</span>
        </p>
      </div>

      <div className="RecipeFormGroup">
        <label>製造量</label>
        <div className="RecipeRegisterFormGroup1">
          <input
            type="number"
            value={recipe.productionQuantity || ''}
            onChange={(e) => handleChange('productionQuantity', e.target.value)}
          />
          <select
            value={recipe.unit || 'g'}
            onChange={(e) => handleChange('unit', e.target.value)}
          >
            <option value="g">g</option>
            <option value="kg">kg</option>
            <option value="ml">ml</option>
            <option value="L">L</option>
            <option value="個">個</option>
            <option value="バット">バット</option>
            <option value="袋">袋</option>
            <option value="玉">玉</option>
            <option value="缶">缶</option>
          </select>
        </div>
      </div>

      <div className="InboundRegisterButtons">
        <button className="RecipeRegisterSaveButton" onClick={handleSave} disabled={loading}>
          {loading ? '保存中...' : 'レシピを保存'}
        </button>

        <button className="RecipeRegisterDeleteButton" onClick={handleDelete}>
          レシピを削除
        </button>
      </div>
    </div>
  );
};

export default RecipeView;
