import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, addDoc, getDocs, doc, updateDoc, setDoc, query, where } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import '../Pages.css';


const InboundOrderRegister = () => {
  const [date, setDate] = useState('');
  const [orders, setOrders] = useState([{ name: '', count: '', unit: '', price: '', company: '', full_price: '', quantity: '' }]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  // Fetch products when the component mounts
  useEffect(() => {
    const fetchProducts = async () => {
      const querySnapshot = await getDocs(collection(db, 'inbound'));
      const productsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProducts(productsList);
    };

    fetchProducts();
  }, []);

  // Handle product change
  const handleProductChange = (index, e) => {
    const selectedProduct = products.find(product => product.name === e.target.value);
    const newOrders = [...orders];
    newOrders[index] = {
      ...newOrders[index],
      name: selectedProduct.name,
      price: selectedProduct.price,
      company: selectedProduct.supplier,
      unit: selectedProduct.unit,
      quantity: selectedProduct.quantity,
      full_price: selectedProduct.price * (newOrders[index].count || 0)
    };
    setOrders(newOrders);
  };

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const newOrders = [...orders];

    if (name === 'count' && value < 0) {
      setErrors({ ...errors, [`count${index}`]: '数量はマイナス値を入力できません。' });
      return;
    }

    newOrders[index] = {
      ...newOrders[index],
      [name]: value,
      full_price: name === 'count' ? value * newOrders[index].price : newOrders[index].full_price
    };
    setOrders(newOrders);
    setErrors({ ...errors, [`${name}${index}`]: '' });
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
    setErrors({ ...errors, date: '' });
  };

  const validate = () => {
    let tempErrors = {};
    if (!date) tempErrors.date = '日付けは必須です。';
    orders.forEach((order, index) => {
      if (!order.name) tempErrors[`name${index}`] = '商品名は必須です。';
      if (!order.count) tempErrors[`count${index}`] = '数量は必須です。';
      if (!order.unit) tempErrors[`unit${index}`] = '単位は必須です。';
    });
    setErrors(tempErrors);
    if (Object.keys(tempErrors).length > 0) {
      window.alert(Object.values(tempErrors).join('\n'));
    }
    return Object.keys(tempErrors).length === 0;
  };

  const addOrder = async () => {
    if (validate()) {
      setLoading(true);
      try {
        for (let order of orders) {
          // Save each order as a separate document in Firebase
          await addDoc(collection(db, 'inbound_order'), {
            date,
            name: order.name,
            count: parseFloat(order.count),
            unit: order.unit,
            price: parseFloat(order.price),
            company: order.company,
            full_price: parseFloat(order.full_price),
            quantity: parseFloat(order.quantity),
          });
  
          // Update stock for the product
          await updateStock(order.name, parseFloat(order.count), order.quantity, order.unit);
        }
  
        // Reset the form after saving all orders
        setDate('');
        setOrders([{ name: '', count: '', unit: '', price: '', company: '', full_price: '', quantity: '' }]);
        alert('すべての発注が登録されました！');
        navigate('/seimen/inbound-order');
      } catch (error) {
        console.error('Error adding order: ', error);
        alert('エラーが発生しました。');
      } finally {
        setLoading(false);
      }
    }
  };
  

  const updateStock = async (itemName, count, productQuantity, unit) => {
    const stockRef = doc(db, 'stock', itemName);
    const stockDoc = await getDocs(query(collection(db, 'stock'), where('name', '==', itemName)));
  
    // ユニットがKg変換可能かどうかをチェック
    const isConvertibleToKg = ['g', 'ml', 'kg', 'l'].includes(unit);
    const totalAddedQuantity = isConvertibleToKg 
      ? convertToKg(productQuantity * count, unit) 
      : productQuantity * count; // そのまま使用
  
    if (!stockDoc.empty) {
      const currentStock = stockDoc.docs[0].data().quantity;
      const currentUnit = stockDoc.docs[0].data().unit;
  
      const updatedQuantity = isConvertibleToKg 
        ? convertToKg(currentStock, currentUnit) + totalAddedQuantity 
        : currentStock + totalAddedQuantity; // 単位が同じ場合にそのまま追加
  
      await updateDoc(stockRef, {
        quantity: updatedQuantity,
        unit: isConvertibleToKg ? 'kg' : unit // `kg`に変換可能な場合は`kg`として保存、それ以外はそのまま
      });
    } else {
      await setDoc(stockRef, {
        name: itemName,
        quantity: totalAddedQuantity,
        unit: isConvertibleToKg ? 'kg' : unit // 新規エントリの場合も同様
      });
    }
  };
  

  const convertToKg = (quantity, unit) => {
    switch (unit) {
      case 'g':
        return quantity / 1000;
      case 'ml':
        return quantity / 1000;
      case 'L':
        return quantity; // lはそのまま
      case 'kg':
      default:
        return quantity; // kgやその他のケースはそのまま
    }
  };
  

  const handleAddProduct = () => {
    setOrders([...orders, { name: '', count: '', unit: '', price: '', company: '', full_price: '', quantity: '' }]);
  };

  const handleDeleteProduct = (index) => {
    const newOrders = orders.filter((_, i) => i !== index);
    setOrders(newOrders);
  };

  const selectedProductNames = orders.map(order => order.name);

  return (
    <div className="InboundRegisterApp">
      <h1>発注登録</h1>
      <div className="InboundRegisterFormGroup">
        <label>日付け<span>(必須)</span></label>
        <div className='date_container'>
          <input name="date" type="date" value={date} onChange={handleDateChange} lang="ja"  className='date_picker' id='date'/>
        </div>
       
      </div>
      <div className="table-container">
        <table className="InboundRegisterTable">
          <thead>
            <tr>
              <th>商品名 <span>(必須)</span></th>
              <th>数量 <span>(必須)</span></th>
              <th>価格</th>
              <th>仕入先</th>
              <th>合計価格</th>
              <th>削除</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order, index) => (
              <tr key={order.name || index}>
                <td>
                  <select name="name" value={order.name} onChange={(e) => handleProductChange(index, e)}>
                    <option>商品を選択</option>
                    {products
                      .filter(product => !selectedProductNames.includes(product.name) || product.name === order.name)
                      .map(product => (
                        <option key={product.id} value={product.name}>
                          {product.name}
                        </option>
                      ))}
                  </select>
                  {errors[`name${index}`] && <p className="error">{errors[`name${index}`]}</p>}
                </td>
                <td className='count_input'>
                  <input
                    name="count"
                    type="number"
                    min="0"
                    value={order.count}
                    onChange={(e) => handleChange(index, e)}
                  />
                  <span>({order.quantity}{order.unit})</span>
                  {errors[`count${index}`] && <p className="error">{errors[`count${index}`]}</p>}
                </td>
                <td>
                  <input name="price" type="number" value={order.price} disabled />
                </td>
                <td>
                  <input name="company" type="text" value={order.company} disabled />
                </td>
                <td>
                  <input name="full_price" type="number" value={order.full_price} disabled />
                </td>
                <td>
                  <button className="InboundRegisterDeleteButton" onClick={() => handleDeleteProduct(index)}>
                    削除
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="InboundRegisterButtons">
        <button className="InboundRegisterSubmitButton" onClick={addOrder} disabled={loading}>
          {loading ? "登録中..." : "発注登録"}
        </button>
        <button className="InboundRegisterCancelButton" onClick={() => navigate('/seimen/inbound-order')}>
          キャンセル
        </button>
        <button className="InboundRegisterAddButton" onClick={handleAddProduct} disabled={loading}>
          商品を追加
        </button>
      </div>
    </div>
  );
};

export default InboundOrderRegister;
