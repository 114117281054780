// src/pages/Recipe.js
import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import '../App.css';

const Recipe = () => {
  const [recipes, setRecipes] = useState([]);
  const navigate = useNavigate();

  // Fetch recipes from Firebase when the component mounts
  useEffect(() => {
    const fetchRecipes = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'recipes'));
        const recipesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setRecipes(recipesList);
      } catch (error) {
        console.error("Error fetching recipes: ", error);
      }
    };

    fetchRecipes();
  }, []);

  const handleCreateRecipe = () => {
    navigate('/seimen/recipe-register'); // Navigate to the RecipeRegister page
  };

  const handleEditRecipe = (recipeId) => {
    console.log(`Navigating to /recipe-view/${recipeId}`); // Debugging output
    navigate(`/seimen/recipe-view/${recipeId}`); // Navigate to the RecipeView page with the selected recipe ID
  };

  return (
    <div className="App">
      <div className='header'>
      <h1>出庫商品 (レシピ)</h1>
      <button className='register-button' onClick={handleCreateRecipe}>レシピ作成</button>
      </div>
      

      <div className="table-container">
        <table className='recipie_table'>
          <thead>
            <tr>
              <th>レシピコード</th>
              <th>レシピ名</th>
              <th className='recipie_table_2th'>修正</th>
            </tr>
          </thead>
          <tbody>
            {recipes.map((recipe) => (
              <tr key={recipe.id}>
                <td>{recipe.code}</td>
                <td>{recipe.name}</td>
                <td className='recipie_table_2th'>
                  <button className='RecipeChangeButton' onClick={() => handleEditRecipe(recipe.id)}>修正</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Recipe;
