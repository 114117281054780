import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import '../Pages.css';

const ShopEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [shop, setShop] = useState({
    code: '',
    name: '',
    address: {
      prefecture: '',
      city: '',
      street: '',
    },
    phone: '',
    email: '',
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchShop = async () => {
      try {
        const shopDoc = await getDoc(doc(db, 'shops', id));
        if (shopDoc.exists()) {
          setShop(shopDoc.data());
        } else {
          alert('取引先が見つかりませんでした。');
          navigate('/seimen/shop-manage');
        }
      } catch (error) {
        console.error('Error fetching shop:', error);
      }
    };

    fetchShop();
  }, [id, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('address.')) {
      const field = name.split('.')[1];
      setShop((prev) => ({
        ...prev,
        address: {
          ...prev.address,
          [field]: value,
        },
      }));
    } else {
      setShop((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      await updateDoc(doc(db, 'shops', id), shop);
      alert('取引先情報が更新されました。');
      navigate('/seimen/shop-manage');
    } catch (error) {
      console.error('Error updating shop:', error);
      alert('更新中にエラーが発生しました。');
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate('/seimen/shop-manage');
  };

  return (
    <div className="App">
      <h1>取引先修正</h1>
      <div className="InboundRegisterFormGroup">
        <label className="sp_label">取引先コード<span>(必須)</span></label>
        <input
          type="text"
          name="code"
          value={shop.code}
          onChange={handleChange}
        />
      </div>
      <div className="InboundRegisterFormGroup">
        <label className="sp_label">取引先名<span>(必須)</span></label>
        <input
          type="text"
          name="name"
          value={shop.name}
          onChange={handleChange}
        />
      </div>
      <div className="InboundRegisterFormGroup">
        <label className="sp_label">都道府県<span>(必須)</span></label>
        <input
          type="text"
          name="address.prefecture"
          value={shop.address.prefecture}
          onChange={handleChange}
        />
      </div>
      <div className="InboundRegisterFormGroup">
        <label className="sp_label">市区町村</label>
        <input
          type="text"
          name="address.city"
          value={shop.address.city}
          onChange={handleChange}
        />
      </div>
      <div className="InboundRegisterFormGroup">
        <label className="sp_label">番地・建物名</label>
        <input
          type="text"
          name="address.street"
          value={shop.address.street}
          onChange={handleChange}
        />
      </div>
      <div className="InboundRegisterFormGroup">
        <label className="sp_label">電話番号<span>(必須)</span></label>
        <input
          type="text"
          name="phone"
          value={shop.phone}
          onChange={handleChange}
        />
      </div>
      <div className="InboundRegisterFormGroup">
        <label className="sp_label">メールアドレス</label>
        <input
          type="email"
          name="email"
          value={shop.email}
          onChange={handleChange}
        />
      </div>

      <div className="InboundRegisterButtons">
        <button className="InboundRegisterSubmitButton" onClick={handleSave} disabled={loading}>
          {loading ? '保存中...' : '保存'}
        </button>
        <button className="InboundRegisterCancelButton" onClick={handleCancel} disabled={loading}>
          キャンセル
        </button>
      </div>
    </div>
  );
};

export default ShopEdit;
