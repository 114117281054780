import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, addDoc, doc, updateDoc } from 'firebase/firestore';
import '../App.css';
import { useNavigate } from 'react-router-dom';

const LossIn = () => {
  const [losses, setLosses] = useState([{ item: '', quantity: '', unitPrice: 0, totalPrice: 0 }]);
  const [inboundItems, setInboundItems] = useState([]);
  const [stockItems, setStockItems] = useState([]); // Fetch stock items
  const [date, setDate] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInboundItems = async () => {
      const querySnapshot = await getDocs(collection(db, 'inbound'));
      setInboundItems(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    const fetchStockItems = async () => {
      const querySnapshot = await getDocs(collection(db, 'stock'));
      setStockItems(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    fetchInboundItems();
    fetchStockItems();
  }, []);

  const handleItemChange = (index, field, value) => {
    const updatedLosses = [...losses];
    const loss = updatedLosses[index];

    if (field === 'item') {
      const selectedItem = inboundItems.find((item) => item.name === value);
      if (selectedItem) {
        const unitPrice =
          selectedItem.unit === 'g'
            ? selectedItem.price / selectedItem.quantity
            : selectedItem.unit === 'kg'
            ? selectedItem.price / (selectedItem.quantity * 1000)
            : selectedItem.unit === 'ml'
            ? selectedItem.price / selectedItem.quantity
            : selectedItem.unit === 'l'
            ? selectedItem.price / (selectedItem.quantity * 1000)
            : selectedItem.price || 0;

        loss.unitPrice = parseFloat(unitPrice) || 0;
      } else {
        loss.unitPrice = 0;
      }
      loss.item = value;
    }

    if (field === 'quantity') {
      const quantity = parseFloat(value) || 0;
      loss.quantity = quantity;
      loss.totalPrice = quantity * (loss.unitPrice || 0);
    }

    updatedLosses[index] = loss;
    setLosses(updatedLosses);
  };

  const addRow = () => {
    setLosses([...losses, { item: '', quantity: '', unitPrice: 0, totalPrice: 0 }]);
  };

  const removeRow = (index) => {
    const updatedLosses = losses.filter((_, i) => i !== index);
    setLosses(updatedLosses);
  };

  const updateStock = async (itemName, quantity, unit) => {
    const stockItem = stockItems.find((stock) => stock.name === itemName);
  
    if (stockItem) {
      const currentQuantity = parseFloat(stockItem.quantity) || 0;
  
      // 単位が g, kg, L, ml の場合は kg に変換
      let decrementQuantity;
      if (['g', 'kg', 'L', 'ml'].includes(unit)) {
        decrementQuantity = convertToKg(quantity, unit);
      } else {
        // その他の単位の場合、直接減算
        decrementQuantity = parseFloat(quantity) || 0;
      }
  
      const newQuantity = currentQuantity - decrementQuantity;
  
      if (newQuantity < 0) {
        alert(`在庫が不足しています: ${itemName}`);
        throw new Error('Insufficient stock');
      }
  
      const stockRef = doc(db, 'stock', stockItem.id);
      await updateDoc(stockRef, { quantity: newQuantity });
    } else {
      alert(`在庫に存在しない商品: ${itemName}`);
      throw new Error('Stock item not found');
    }
  };
  
  const convertToKg = (quantity, unit) => {
    switch (unit) {
      case 'g':
        return quantity / 1000;
      case 'ml':
        return quantity / 1000;
      case 'L':
        return quantity; // Lはそのまま
      case 'kg':
        return quantity; // kgはそのまま
      default:
        return quantity; // その他の単位はそのまま返す
    }
  };
  
  
  

  const recordLosses = async () => {
    if (!date) {
      alert('日付を入力してください。');
      return;
    }
  
    const validLosses = losses.filter((loss) => loss.item && loss.quantity > 0);
  
    if (validLosses.length === 0) {
      alert('少なくとも1つの有効な廃棄項目を入力してください。');
      return;
    }
  
    try {
      await Promise.all(
        validLosses.map(async (loss) => {
          await addDoc(collection(db, 'loss_in'), {
            date,
            item: loss.item,
            quantity: parseFloat(loss.quantity),
            unitPrice: loss.unitPrice,
            totalPrice: parseFloat(loss.totalPrice.toFixed(2)),
          });
  
          const selectedItem = inboundItems.find((item) => item.name === loss.item);
          await updateStock(loss.item, parseFloat(loss.quantity), selectedItem.unit);
        })
      );
  
      alert('廃棄記録が保存され、在庫が更新されました。');
      setLosses([{ item: '', quantity: '', unitPrice: 0, totalPrice: 0 }]);
      setDate('');
      navigate('/seimen/loss');
    } catch (error) {
      console.error('Error recording losses or updating stock:', error);
    }
  };
  
  

  const getAvailableItems = (currentIndex) => {
    const selectedItems = losses.map((loss) => loss.item);
    return inboundItems.filter(
      (item) => !selectedItems.includes(item.name) || item.name === losses[currentIndex].item
    );
  };

  const handleCancel = () => {
    navigate('/seimen/loss');
  };


  return (
    <div className="App">
      <h1>廃棄記録</h1>
      <label>
        日付:
        <input type="date" value={date} onChange={(e) => setDate(e.target.value)} id='date'/>
      </label>

      <div className='table-container'>
      <table className="loss-table">
        <thead>
          <tr>
            <th>商品名</th>
            <th>数量</th>
            <th>単価 (¥/g)</th>
            <th>合計価格 (¥)</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          {losses.map((loss, index) => (
            <tr key={index}>
              <td>
                <select
                  value={loss.item}
                  onChange={(e) => handleItemChange(index, 'item', e.target.value)}
                >
                  <option value="">商品を選択</option>
                  {getAvailableItems(index).map((item) => (
                    <option key={item.id} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <input
                  type="number"
                  value={loss.quantity}
                  onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                  placeholder="数量"
                />
                {loss.unit}
              </td>
              <td>¥{loss.unitPrice.toFixed(2)}</td>
              <td>¥{loss.totalPrice.toFixed(2)}</td>
              <td>
                <button className='delete-button' onClick={() => removeRow(index)}>削除</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      </div>
      
      <button className='LineAddButton' onClick={addRow}>行を追加</button>

      <div className='InboundRegisterButtons'>
        <button className='LossRegisterButton' onClick={recordLosses}>廃棄記録</button>
        <button className='InboundRegisterCancelButton' onClick={handleCancel} >キャンセル</button>            
      </div>
      
    </div>
  );
};

export default LossIn;
