import React, { useState } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../firebase';
import { setDoc, doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import '../Pages.css';

function SignUp() {
  const [companyCode, setCompanyCode] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();
  
    if (companyCode !== '7923') {
      alert('企業コードが正しくありません。正しいコードを入力してください。');
      return;
    }
  
    if (password !== confirmPassword) {
      alert('パスワードが一致しません。もう一度ご確認ください。');
      return;
    }
  
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
  
      await setDoc(doc(db, 'users', userCredential.user.uid), {
        uid: userCredential.user.uid,
        companyCode,
        employeeId,
        name,
        email,
        createdAt: new Date(),
      });
      
  
      alert('登録が完了しました！');
      navigate('/seimen/login');
    } catch (error) {
      let message = '登録に失敗しました。';
  
      if (error.code === 'auth/email-already-in-use') {
        message = 'このメールアドレスはすでに登録されています。';
      } else if (error.code === 'auth/invalid-email') {
        message = 'メールアドレスの形式が正しくありません。';
      } else if (error.code === 'auth/weak-password') {
        message = 'パスワードは6文字以上で入力してください。';
      }
  
      alert(message);
    }
  };
  

  return (
    <div className="login-wrapper">
      <div className="login-card">
        <h2 className="login-title">会員登録</h2>
        <form onSubmit={handleSignUp} className="login-form">
          <input
            type="text"
            placeholder="企業コード"
            value={companyCode}
            onChange={(e) => setCompanyCode(e.target.value)}
            required
            className="login-input"
            id="sign-input"
          />
          <input
            type="text"
            placeholder="事業員番号"
            value={employeeId}
            onChange={(e) => setEmployeeId(e.target.value)}
            required
            className="login-input"
            id="sign-input"
          />
          <input
            type="text"
            placeholder="名前"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="login-input"
            id="sign-input"
          />
          <input
            type="email"
            placeholder="メールアドレス"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="login-input"
          />

          {/* パスワード入力＋表示切り替え */}
          <div className="password-wrapper">
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder="パスワード（6文字以上）"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="login-input"
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="toggle-password"
            >
              {showPassword ? '非表示' : '表示'}
            </button>
          </div>

          {/* 確認パスワード入力＋表示切り替え */}
          <div className="password-wrapper">
            <input
              type={showConfirm ? 'text' : 'password'}
              placeholder="パスワード再入力"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              className="login-input"
              
            />
            <button
              type="button"
              onClick={() => setShowConfirm(!showConfirm)}
              className="toggle-password"
              id="sign-input"
            >
              {showConfirm ? '非表示' : '表示'}
            </button>
          </div>

          <button type="submit" className="login-button">登録</button>
        </form>
        <p className="login-subtext">
          すでにアカウントをお持ちの方は{' '}
          <button
            className="signup-link"
            onClick={() => navigate('/seimen/login')}
          >
            ログイン
          </button>
        </p>
      </div>
    </div>
  );
}

export default SignUp;
