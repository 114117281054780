import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import '../App.css';

const Inbound = () => {
  const [items, setItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items per page
  const navigate = useNavigate();

  useEffect(() => {
    const fetchItems = async () => {
      const querySnapshot = await getDocs(collection(db, 'inbound'));
      setItems(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    fetchItems();
  }, []);

  const handleRegisterClick = () => {
    navigate('/seimen/inbound-register');
  };

  const handleEditClick = (item) => {
    navigate(`/seimen/inbound-edit/${item.id}`);
  };

  const handleDeleteClick = async (id) => {
    const confirmDelete = window.confirm('本当に削除しますか？この操作は取り消せません。');
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, 'inbound', id));
        setItems((prevItems) => prevItems.filter((item) => item.id !== id));
        alert('削除が完了しました。');
      } catch (error) {
        console.error('削除中にエラーが発生しました: ', error);
        alert('削除中にエラーが発生しました。');
      }
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const paginate = (data) => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return data.slice(indexOfFirstItem, indexOfLastItem);
  };

  const totalPages = () => Math.ceil(items.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.code.toLowerCase().includes(searchTerm.toLowerCase())
  ).length / itemsPerPage);

  const changePage = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages()) {
      setCurrentPage(pageNumber);
    }
  };

  // Filter items based on the search term
  const filteredItems = items.filter(
    (item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.code.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="App">
      <div className="header">
        <h1>入庫商品一覧</h1>
        <button className="register-button" onClick={handleRegisterClick}>
          新規商品登録
        </button>
      </div>
      <div className="search-bar">
        <input
          type="text"
          placeholder="商品検索..."
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>

      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>アイテムコード</th>
              <th>アイテム名</th>
              <th>価格</th>
              <th>数量</th>
              <th>仕入先</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            {paginate(filteredItems).map((item) => (
              <tr key={item.id}>
                <td>{item.code}</td>
                <td>{item.name}</td>
                <td>{parseFloat(item.price).toLocaleString()}円</td>
                <td>{item.quantity}{item.unit}</td>
                <td>{item.supplier}</td>
                <td>
                  <button className="edit-button" onClick={() => handleEditClick(item)}>
                    変更
                  </button>
                  <button className="delete-button" onClick={() => handleDeleteClick(item.id)}>
                    削除
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="pagination">
        <button onClick={() => changePage(currentPage - 1)}>前</button>
        <span>
          {currentPage} / {totalPages()}
        </span>
        <button onClick={() => changePage(currentPage + 1)}>次</button>
      </div>
    </div>
  );
};

export default Inbound;
