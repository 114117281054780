import React, { useEffect, useState } from 'react';
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import '../App.css';

const ShopManage = () => {
  const [shops, setShops] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // 1ページあたりの項目数
  const navigate = useNavigate();

  useEffect(() => {
    const fetchShops = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'shops'));
        const shopData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setShops(shopData);
      } catch (error) {
        console.error('Error fetching shops:', error);
      }
    };

    fetchShops();
  }, []);

  // 削除処理
  const handleDelete = async (id) => {
    if (window.confirm('この取引先を削除しますか？')) {
      try {
        await deleteDoc(doc(db, 'shops', id));
        setShops(shops.filter((shop) => shop.id !== id));
        alert('取引先が削除されました。');
      } catch (error) {
        console.error('Error deleting shop:', error);
        alert('削除中にエラーが発生しました。');
      }
    }
  };

  // フィルタリングしたショップを取得
  const filteredShops = shops.filter((shop) => {
    const addressString = shop.address
      ? `${shop.address.prefecture || ''} ${shop.address.city || ''} ${shop.address.street || ''}`
      : '';
    const searchString = (shop.name || '') + addressString;
    return searchString.toLowerCase().includes(searchTerm.toLowerCase());
  });

  // ページネーション関連の計算
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentShops = filteredShops.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredShops.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="App">
      <div className="header">
        <h1>出庫先管理</h1>
        <button className="register-button" onClick={() => navigate('/seimen/shops-register')}>
          出庫先登録
        </button>
      </div>
      <div className="search-container">
        <input
          id="search-bar"
          type="text"
          placeholder="取引先名や住所を検索"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-box"
        />
      </div>

      <div className="table-container">
        <table border="1" cellPadding="10" cellSpacing="0">
          <thead>
            <tr>
              <th>取引先コード</th>
              <th>名前</th>
              <th>住所</th>
              <th>電話番号</th>
              <th>メールアドレス</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            {currentShops.length > 0 ? (
              currentShops.map((shop) => (
                <tr key={shop.id}>
                  <td>{shop.code || 'N/A'}</td>
                  <td>{shop.name || 'N/A'}</td>
                  <td>
                    {shop.address
                      ? `${shop.address.prefecture || ''} ${shop.address.city || ''} ${shop.address.street || ''}`
                      : 'N/A'}
                  </td>
                  <td>{shop.phone || 'N/A'}</td>
                  <td>{shop.email || 'N/A'}</td>

                  <td className="edit-buttons">
                    <button
                      className="edit-button"
                      onClick={() => navigate(`/seimen/shops-edit/${shop.id}`)}
                    >
                      編集
                    </button>
                    <button
                      className="delete-button"
                      onClick={() => handleDelete(shop.id)}
                    >
                      削除
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">検索結果がありません</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="pagination-button"
        >
          前
        </button>
        <span className="pagination-info">
          {currentPage} / {totalPages}
        </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="pagination-button"
        >
          次
        </button>
      </div>
    </div>
  );
};

export default ShopManage;
