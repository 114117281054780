import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import '../Pages.css';

const InboundRegister = () => {
  const [item, setItem] = useState({
    code: '',
    name: '',
    specification: '',
    quantity: '',
    unit: '',
    price: '',
    supplier: '',
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); // Loading state
  const [suppliers, setSuppliers] = useState([]); // List of suppliers
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSuppliers = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'inbound_shops'));
        const shops = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSuppliers(shops);
      } catch (error) {
        console.error('Error fetching suppliers:', error);
      }
    };

    fetchSuppliers();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Prevent negative input for quantity and price
    if ((name === 'quantity' || name === 'price') && value < 0) {
      setErrors({ ...errors, [name]: 'マイナス値は入力できません。' });
      return;
    }

    setItem({ ...item, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const validate = () => {
    let tempErrors = {};
    if (!item.code) tempErrors.code = '商品コードは必須です。';
    if (!item.name) tempErrors.name = '商品名は必須です。';
    if (!item.quantity) tempErrors.quantity = '入数は必須です。';
    if (!item.unit) tempErrors.unit = '単位は必須です。';
    if (!item.price) tempErrors.price = '価格は必須です。';
    if (!item.supplier) tempErrors.supplier = '仕入先を選択してください。';
    setErrors(tempErrors);
    if (Object.keys(tempErrors).length > 0) {
      window.alert(Object.values(tempErrors).join('\n'));
    }
    return Object.keys(tempErrors).length === 0;
  };

  const addItem = async () => {
    if (validate()) {
      setLoading(true); // Set loading to true when the request starts
      try {
        // Check if the product code already exists
        const q = query(collection(db, 'inbound'), where('code', '==', item.code));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          window.alert('この商品コードは既に存在します。別のコードを入力してください。');
        } else {
          await addDoc(collection(db, 'inbound'), item);
          setItem({
            code: '',
            name: '',
            specification: '',
            quantity: '',
            unit: '',
            price: '',
            supplier: '',
          });
          navigate('/seimen/inbound');
        }
      } catch (error) {
        console.error('Error adding document: ', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCancel = () => {
    navigate('/seimen/inbound');
  };

  return (
    <div className="InboundRegisterApp">
      <h1>新規商品登録</h1>
      <div className="InboundRegisterFormGroup">
        <label className="sp_label">
          商品コード<span>(必須)</span>
        </label>
        <input name="code" type="text" value={item.code} onChange={handleChange} />
      </div>
      <div className="InboundRegisterFormGroup">
        <label className="sp_label">
          商品名<span>(必須)</span>
        </label>
        <input name="name" type="text" value={item.name} onChange={handleChange} />
      </div>

      <div className="InboundRegisterFormGroup">
        <label className="sp_label">規格</label>
        <input name="specification" type="text" value={item.specification} onChange={handleChange} className="InboundRegisterFlex1" />
      </div>
      <div className="InboundRegisterFormGroup">
        <label className="sp_label">
          入数／単位<span>(必須)</span>
        </label>
        <input name="quantity" type="number" min="0" value={item.quantity} onChange={handleChange} className="InboundRegisterFlex2" />

        <div className="InboundRegisterInputGroup">
          <select name="unit" value={item.unit} onChange={handleChange} className="InboundRegisterFlex3">
            <option value="">単位</option>
            <option value="g">g</option>
            <option value="kg">kg</option>
            <option value="ml">ml</option>
            <option value="L">L</option>
            <option value="個">個</option>
            <option value="バット">バット</option>
            <option value="袋">袋</option>
            <option value="玉">玉</option>
          </select>
        </div>
      </div>
      <div className="InboundRegisterFormGroup">
        <label className="sp_label">
          価格<span>(必須)</span>
        </label>
        <input name="price" type="number" min="0" value={item.price} onChange={handleChange} />
      </div>
      <div className="InboundRegisterFormGroup">
        <label className="sp_label">
          入庫先<span>(必須)</span>
        </label>
        <select name="supplier" value={item.supplier} onChange={handleChange}>
          <option value="">入庫先を選択</option>
          {suppliers.map((supplier) => (
            <option key={supplier.id} value={supplier.name}>
              {supplier.name} ({supplier.code})
            </option>
          ))}
        </select>
      </div>

      <div className="InboundRegisterButtons">
        <button className="InboundRegisterSubmitButton" onClick={addItem} disabled={loading}>
          {loading ? '登録中...' : '商品登録'}
        </button>
        <button className="InboundRegisterCancelButton" onClick={handleCancel} disabled={loading}>
          キャンセル
        </button>
      </div>
    </div>
  );
};

export default InboundRegister;
