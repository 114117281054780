import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import '../App.css';

const InboundEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [item, setItem] = useState({
    code: '',
    name: '',
    specification: '',
    quantity: '',
    unit: '',
    price: '',
    supplier: '',
  });
  const [suppliers, setSuppliers] = useState([]); // List of suppliers
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchItem = async () => {
      try {
        const itemDoc = await getDoc(doc(db, 'inbound', id));
        if (itemDoc.exists()) {
          setItem(itemDoc.data());
        } else {
          alert('商品が見つかりませんでした。');
          navigate('/seimen/inbound');
        }
      } catch (error) {
        console.error('Error fetching item:', error);
        alert('データ取得中にエラーが発生しました。');
      }
    };

    const fetchSuppliers = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'inbound_shops'));
        const shops = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSuppliers(shops);
      } catch (error) {
        console.error('Error fetching suppliers:', error);
      }
    };

    fetchItem();
    fetchSuppliers();
  }, [id, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setItem({
      ...item,
      [name]: value,
    });
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      await updateDoc(doc(db, 'inbound', id), item);
      alert('商品情報が更新されました。');
      navigate('/seimen/inbound');
    } catch (error) {
      console.error('Error updating item:', error);
      alert('更新中にエラーが発生しました。');
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate('/seimen/inbound');
  };

  return (
    <div className="App">
      <h1>商品情報修正</h1>
      <div className="InboundEditFormGroup">
        <label>商品コード</label>
        <input
          name="code"
          type="text"
          value={item.code}
          onChange={handleChange}
          disabled
          className="InboundEditInput"
        />
      </div>
      <div className="InboundEditFormGroup">
        <label>商品名</label>
        <input
          name="name"
          type="text"
          value={item.name}
          onChange={handleChange}
          className="InboundEditInput"
        />
      </div>
      <div className="InboundEditFormGroup">
        <label>規格</label>
        <input
          name="specification"
          type="text"
          value={item.specification}
          onChange={handleChange}
          className="InboundEditInput"
        />
      </div>
      <div className="InboundEditFormGroup">
        <label>入数／単位</label>
        <div className="InboundEditInputGroup">
          <input
            name="quantity"
            type="number"
            value={item.quantity}
            onChange={handleChange}
            className="InboundEditFlex2"
          />
          <span className="InboundEditSeparator">／</span>
          <select
            name="unit"
            value={item.unit}
            onChange={handleChange}
            className="InboundEditFlex3"
          >
            <option value="">単位</option>
            <option value="g">g</option>
            <option value="kg">kg</option>
            <option value="ml">ml</option>
            <option value="L">L</option>
            <option value="個">個</option>
            <option value="バット">バット</option>
            <option value="袋">袋</option>
            <option value="玉">玉</option>
          </select>
        </div>
      </div>
      <div className="InboundEditFormGroup">
        <label>価格</label>
        <input
          name="price"
          type="number"
          value={item.price}
          onChange={handleChange}
          className="InboundEditInput"
        />
      </div>
      <div className="InboundEditFormGroup">
        <label>仕入先</label>
        <select
          name="supplier"
          value={item.supplier}
          onChange={handleChange}
          className="InboundEditInput"
        >
          <option value="">仕入先を選択</option>
          {suppliers.map((supplier) => (
            <option key={supplier.id} value={supplier.name}>
              {supplier.name} ({supplier.code})
            </option>
          ))}
        </select>
      </div>
      <div className="InboundEditButtons">
        <button className="InboundEditSaveButton" onClick={handleSave} disabled={loading}>
          {loading ? '保存中...' : '保存'}
        </button>
        <button className="InboundEditCancelButton" onClick={handleCancel} disabled={loading}>
          キャンセル
        </button>
      </div>
    </div>
  );
};

export default InboundEdit;
