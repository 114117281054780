import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, addDoc, doc, getDoc, updateDoc, Timestamp } from 'firebase/firestore';
import '../App.css';
import { useNavigate } from 'react-router-dom';

const ShopOut = () => {
  const [shops, setShops] = useState([]);
  const [products, setProducts] = useState([]);
  const [shipments, setShipments] = useState([{ productName: '', quantity: 0, unitPrice: 0, totalCost: 0 }]);
  const [shopName, setShopName] = useState('');
  const [date, setDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const shopSnapshot = await getDocs(collection(db, 'shops'));
        setShops(shopSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));

        const productSnapshot = await getDocs(collection(db, 'recipes'));
        setProducts(productSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const addProductEntry = () => {
    setShipments([...shipments, { productName: '', quantity: 0, unitPrice: 0, totalCost: 0 }]);
  };

  const handleShipmentChange = async (index, field, value) => {
    const updatedShipments = [...shipments];
    updatedShipments[index][field] = value;

    if (field === 'productName') {
      try {
        const productRef = doc(db, 'recipes', value);
        const productDoc = await getDoc(productRef);
        if (productDoc.exists()) {
          const { unitPrice } = productDoc.data();
          updatedShipments[index].unitPrice = unitPrice || 0;
          updatedShipments[index].totalCost = (unitPrice || 0) * (updatedShipments[index].quantity || 0);
        } else {
          console.error('Product not found in recipes');
          updatedShipments[index].unitPrice = 0;
          updatedShipments[index].totalCost = 0;
        }
      } catch (error) {
        console.error('Error fetching unitPrice:', error);
      }
    }

    if (field === 'quantity') {
      const unitPrice = updatedShipments[index].unitPrice || 0;
      updatedShipments[index].totalCost = unitPrice * (parseFloat(value) || 0);
    }

    setShipments(updatedShipments);
  };

  const removeShipment = (index) => {
    const updatedShipments = shipments.filter((_, i) => i !== index);
    setShipments(updatedShipments);
  };

  const availableProducts = (index) => {
    const selectedProductIds = shipments
      .filter((_, idx) => idx !== index)
      .map((shipment) => shipment.productName);
    return products.filter((product) => !selectedProductIds.includes(product.id));
  };

  const handleRegisterShipment = async () => {
    if (!date || !shopName) {
      alert('日付と店舗を選択してください。');
      return;
    }
  
    if (shipments.some((shipment) => !shipment.productName || shipment.quantity <= 0)) {
      alert('すべての項目を正しく入力してください。');
      return;
    }
  
    setLoading(true);
    try {
      // 在庫不足チェック用リスト
      const insufficientStocks = [];
  
      // 1. 在庫チェック
      for (const shipment of shipments) {
        const productRef = doc(db, 'recipes', shipment.productName);
        const productDoc = await getDoc(productRef);
  
        if (!productDoc.exists()) {
          insufficientStocks.push({
            productName: shipment.productName,
            message: `商品が見つかりません: ${shipment.productName}`,
          });
          continue;
        }
  
        const productData = productDoc.data();
  
        // `production` コレクションから対応する在庫を取得
        const productionSnapshot = await getDocs(collection(db, 'production'));
        const matchingProduct = productionSnapshot.docs.find(
          (doc) => doc.data().recipeName.trim() === productData.name.trim()
        );
  
        if (!matchingProduct) {
          insufficientStocks.push({
            productName: productData.name,
            message: `"production"に在庫が見つかりません: ${productData.name}`,
          });
          continue;
        }
  
        const productionData = matchingProduct.data();
        const currentStock = parseFloat(productionData.productionQuantity || 0);
        const shipmentQuantity = parseFloat(shipment.quantity || 0);
  
        console.log(`商品: ${productData.name}, 現在の在庫: ${currentStock} ${productionData.unit}, 必要数量: ${shipmentQuantity}`);
  
        // 在庫不足のチェック
        if (shipmentQuantity > currentStock) {
          insufficientStocks.push({
            productName: productData.name,
            message: `在庫不足: ${productData.name} の現在在庫は ${currentStock} ${productionData.unit} です。必要数量: ${shipmentQuantity}`,
          });
        }
      }
  
      // 2. 在庫不足があれば警告を表示し、処理を中断
      if (insufficientStocks.length > 0) {
        const errorMessages = insufficientStocks.map((stock) => stock.message).join('\n');
        alert(`在庫確認エラー:\n${errorMessages}`);
        setLoading(false);
        return;
      }
  
      // 3. 在庫を更新し、出荷データを登録
      const promises = shipments.map(async (shipment) => {
        const productRef = doc(db, 'recipes', shipment.productName);
        const productDoc = await getDoc(productRef);
        const productData = productDoc.data();
  
        // `production` の在庫更新
        const productionSnapshot = await getDocs(collection(db, 'production'));
        const matchingProduct = productionSnapshot.docs.find(
          (doc) => doc.data().recipeName.trim() === productData.name.trim()
        );
  
        const productionDocRef = doc(db, 'production', matchingProduct.id);
        const currentStock = parseFloat(matchingProduct.data().productionQuantity || 0);
        const shipmentQuantity = parseFloat(shipment.quantity || 0);
  
        const updatedStock = currentStock - shipmentQuantity;
        await updateDoc(productionDocRef, { productionQuantity: updatedStock });
  
        console.log(`商品: ${productData.name}, 更新後の在庫: ${updatedStock} ${matchingProduct.data().unit}`);
  
        // 出荷登録
        const newShipment = {
          createdAt: Timestamp.now(),
          date,
          shopName,
          productName: productData.name || 'Unknown Product',
          quantity: shipment.quantity,
          unitPrice: shipment.unitPrice,
          totalCost: shipment.totalCost,
        };
  
        return await addDoc(collection(db, 'shipments'), newShipment);
      });
  
      await Promise.all(promises);
  
      setSuccessMessage('出荷登録が完了しました！');
      setShipments([{ productName: '', quantity: 0, unitPrice: 0, totalCost: 0 }]);
      setShopName('');
      setDate('');
      alert('出荷登録が完了しました！');
      navigate('/seimen/shipments');
    } catch (error) {
      console.error('出荷登録中にエラーが発生しました: ', error);
      alert('出荷登録に失敗しました。');
    } finally {
      setLoading(false);
    }
  };
  
  

  const handleCancel = () => {
    setShipments([{ productName: '', quantity: 0, unitPrice: 0, totalCost: 0 }]);
    setShopName('');
    setDate('');
    navigate('/seimen/shipments');
  };

  return (
    <div className="App">
      <div className="header">
        <h1>出荷登録</h1>
      </div>

      <div className="date-input-container">
        <label htmlFor="date">日付:</label>
        <input type="date" id="date" value={date} onChange={(e) => setDate(e.target.value)}/>
      </div>

      <div className="select-field">
        <label htmlFor="shop">出荷先:</label>
        <select id="shop" value={shopName} onChange={(e) => setShopName(e.target.value)}>
          <option value="">出荷先を選択</option>
          {shops.map((shop) => (
            <option key={shop.id} value={shop.name}>
              {shop.name}
            </option>
          ))}
        </select>
      </div>

      <div className="table-container">
        <table className="RecipeRegisterTable">
          <thead>
            <tr>
              <th>商品名</th>
              <th>数量</th>
              <th>単価</th>
              <th>合計金額</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            {shipments.map((shipment, index) => (
              <tr key={index}>
                <td>
                  <select
                    value={shipment.productName}
                    onChange={(e) => handleShipmentChange(index, 'productName', e.target.value)}
                    disabled={!shopName}
                  >
                    <option value="">商品を選択</option>
                    {availableProducts(index).map((product) => (
                      <option key={product.name} value={product.id}>
                        {product.name}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <input
                    type="number"
                    value={shipment.quantity || ''}
                    onChange={(e) => handleShipmentChange(index, 'quantity', e.target.value)}
                    disabled={!shipment.productName}
                  />
                </td>
                <td>¥{shipment.unitPrice?.toFixed(2) || '0.00'}</td>
                <td>¥{shipment.totalCost?.toFixed(2) || '0.00'}</td>
                <td>
                  <button onClick={() => removeShipment(index)} className="delete-button">
                    削除
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <button
        className="add-product-button"
        onClick={addProductEntry}
        disabled={!shopName || availableProducts(-1).length === 0}
      >
        商品を追加
      </button>

      <div className="action-buttons">
        <button className="InboundRegisterSubmitButton" onClick={handleRegisterShipment} disabled={loading}>
          {loading ? '登録中...' : '出荷登録'}
        </button>
        <button className="InboundRegisterCancelButton" onClick={handleCancel} disabled={loading}>
          キャンセル
        </button>
      </div>

      {successMessage && <p className="success-message">{successMessage}</p>}
    </div>
  );
};

export default ShopOut;
