import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, addDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import '../App.css';

const RecipeRegister = () => {
  const [recipeName, setRecipeName] = useState('');
  const [recipeCode, setRecipeCode] = useState(''); // レシピコード
  const [ingredients, setIngredients] = useState([{ name: '', quantity: '', unitPrice: 0, cost: 0 }]);
  const [availableIngredients, setAvailableIngredients] = useState([]);
  const [productionQuantity, setProductionQuantity] = useState('');
  const [unit, setUnit] = useState('g');
  const [totalCost, setTotalCost] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchIngredients = async () => {
      const querySnapshot = await getDocs(collection(db, 'inbound'));
      const ingredientsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setAvailableIngredients(ingredientsList);
    };

    fetchIngredients();
  }, []);

  const addIngredient = () => {
    setIngredients([...ingredients, { name: '', quantity: '', unitPrice: 0, cost: 0 }]);
  };

  const handleIngredientChange = (index, field, value) => {
    const newIngredients = [...ingredients];
  
    if (field === 'name') {
      const selectedIngredient = availableIngredients.find(ingredient => ingredient.name === value);
  
      if (selectedIngredient) {

        if (['g', 'kg', 'l', 'ml'].includes(selectedIngredient.unit)) {
          const pricePerGramOrLiter = selectedIngredient.unit === 'g'
            ? selectedIngredient.price / selectedIngredient.quantity
            : selectedIngredient.unit === 'kg'
            ? selectedIngredient.price / (selectedIngredient.quantity * 1000)
            : selectedIngredient.unit === 'ml'
            ? selectedIngredient.price / selectedIngredient.quantity
            : selectedIngredient.price / (selectedIngredient.quantity * 1000);

            newIngredients[index] = {
              ...newIngredients[index],
              name: value,
              unitPrice: pricePerGramOrLiter,
            };
        } else {
          newIngredients[index] = {
            ...newIngredients[index],
            name: value,
            unitPrice: selectedIngredient.price,
          }; 
        }
      }
    } else if (field === 'quantity') {
      if (value === '') {
        // クリア時に値とコストをリセット
        newIngredients[index].quantity = '';
        newIngredients[index].cost = 0;
      } else {
        const parsedValue = parseFloat(value);
        if (!isNaN(parsedValue) && parsedValue >= 0) {
          newIngredients[index].quantity = parsedValue;
          newIngredients[index].cost = parsedValue * newIngredients[index].unitPrice;
        }
      }
    }
  
    setIngredients(newIngredients);
    updateTotalCost(newIngredients);
  };
  

  const updateTotalCost = (ingredients) => {
    const total = ingredients.reduce((acc, ingredient) => acc + (ingredient.cost || 0), 0);
    setTotalCost(total);
  };

  const removeIngredient = (index) => {
    const newIngredients = ingredients.filter((_, i) => i !== index);
    setIngredients(newIngredients);
    updateTotalCost(newIngredients);
  };

  const saveRecipe = async () => {
    if (!recipeCode) {
      alert('レシピ番号を入力してください。');
      return;
    }
  
    if (!recipeName) {
      alert('レシピ名を入力してください。');
      return;
    }
  
    if (ingredients.length === 0 || ingredients.some(ingredient => !ingredient.name || !ingredient.quantity)) {
      alert('すべての原材料の名前と数量を入力してください。');
      return;
    }
  
    if (!productionQuantity || productionQuantity <= 0) {
      alert('製造量を入力してください。');
      return;
    }
  
    const recipeData = {
      name: recipeName,
      code: recipeCode,
      ingredients: ingredients.map(ingredient => ({
        name: ingredient.name,
        quantity: parseFloat(ingredient.quantity),
        cost: parseFloat(ingredient.cost),
      })),
      totalCost: parseFloat(totalCost),
      productionQuantity: parseFloat(productionQuantity),
      unit,
      unitPrice: productionQuantity > 0 ? totalCost / productionQuantity : 0,
    };
  
    try {
      await addDoc(collection(db, 'recipes'), recipeData);
      setRecipeName('');
      setRecipeCode('');
      setIngredients([{ name: '', quantity: '', unitPrice: 0, cost: 0 }]);
      setProductionQuantity('');
      setTotalCost(0);
      alert('レシピが保存されました！');
      navigate('/seimen/recipe');
    } catch (error) {
      console.error('Error saving recipe:', error);
      alert('エラーが発生しました。');
    }
  };
  

  const handleBack = () => {
    navigate('/seimen/recipe');
  };

  const selectedIngredientNames = ingredients.map(ingredient => ingredient.name);

  return (
    <div className="App InboundRegisterApp">
      <h1>レシピ登録</h1>


      <div className="RecipeRegisterFormGroup">
        <label>
          レシピ番号<span className="required"> (必須)</span>
        </label>
        <input
          type="text"
          value={recipeCode}
          onChange={(e) => setRecipeCode(e.target.value)}
          placeholder="レシピコード"
        />
      </div>


   
      <div className="RecipeRegisterFormGroup RecipeName">
        <label>レシピ名<span className='required'> (必須)</span></label>
        <input
          type="text"
          value={recipeName}
          onChange={(e) => setRecipeName(e.target.value)}
          placeholder="レシピ名"
        />
      </div>

      <p className='WorningText'><span>※</span> 使用量はグラム(g)でご入力ください</p>

      <div className="table-container">
  <table className="RecipeRegisterTable">
    <thead>
      <tr>
        <th>#</th>
        <th>原材料</th>
        <th>使用量</th>
        <th>単価</th>
        <th>原価</th>
        <th>削除</th>
      </tr>
    </thead>
    <tbody>
      {ingredients.map((ingredient, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>
            <select
              className="ProductNames"
              value={ingredient.name}
              onChange={(e) => handleIngredientChange(index, 'name', e.target.value)}
            >
              <option value="">原材料を選択してください</option>
              {availableIngredients
                .filter(
                  (ingredientOption) =>
                    !selectedIngredientNames.includes(ingredientOption.name) ||
                    ingredientOption.name === ingredients[index].name
                )
                .map((ingredientOption) => (
                  <option key={ingredientOption.id} value={ingredientOption.name}>
                    {ingredientOption.name}
                  </option>
                ))}
            </select>
          </td>
          <td>
            <input
              type="number"
              placeholder="使用量"
              value={ingredient.quantity}
              onChange={(e) => handleIngredientChange(index, 'quantity', e.target.value)}
            />
          </td>
          <td>
            {Number(ingredient.unitPrice) > 0 
              ? `¥${Number(ingredient.unitPrice).toFixed(2)}` 
              : '¥0.00'}
          </td>
          <td>
            {ingredient.cost > 0 ? `¥${ingredient.cost.toFixed(0)}` : '¥0'}
          </td>
          <td>
            <button
              className="RecipeRegisterDeleteButtonSub"
              onClick={() => removeIngredient(index)}
            >
              削除
            </button>
          </td>
        </tr>
      ))}
    </tbody>

  </table>
</div>


      <button className="RecipeRegisterAddButton" onClick={addIngredient}>原材料を追加</button>

      <div className="RecipeRegisterFormGroup">
        <label>製造量<span className='required'>(必須)</span></label>
        <div className="RecipeRegisterFormGroup1">
          <input
            type="number"
            min="0"
            value={productionQuantity}
            onChange={(e) => setProductionQuantity(e.target.value)}
          />
          <select
            value={unit}
            onChange={(e) => setUnit(e.target.value)}
          >
            <option value="g">g</option>
            <option value="kg">kg</option>
            <option value="ml">ml</option>
            <option value="L">L</option>
            <option value="個">個</option>
            <option value="バット">バット</option>
            <option value="袋">袋</option>
            <option value="玉">玉</option>
            <option value="缶">缶</option>
            <option value="ポリタンク">ポリタンク</option>
            <option value="寸胴">寸胴</option>
            <option value="パック">パック</option>
            <option value="ペットボトル(800cc)">ペットボトル 800cc</option>
            <option value="ペットボトル(200cc)">ペットボトル 200cc</option>
          </select>
        </div>
      </div>

      <div className="RecipeRegisterFormGroup">
        <p>
          単価 (製造量あたり): ¥
          {productionQuantity > 0 ? (totalCost / productionQuantity).toFixed(2) : '0.00'}
        </p>
        <h3>合計金額: ¥{totalCost.toFixed(2)}</h3>
      </div>

      <div className="InboundRegisterButtons">
        <button className="InboundRegisterSubmitButton" onClick={saveRecipe}>
          レシピを保存
        </button>
        <button className="InboundRegisterCancelButton" onClick={handleBack}>
          キャンセル
        </button>
      </div>
    </div>
  );
};

export default RecipeRegister;